// API for interacting with Analytics
import { 
    ANALYTICS_ENDPOINT, 
    ANALYTIC_KEYS, 
    AUDIT_LOGS_ENDPOINT,
    AUDIT_LOG_DISPLAY_NAMES,
    JOBS_ENDPOINT,
    JOB_KEYS,
} from "../utils/constants";
import { api_consolidator_error_handler } from "../utils/api_utils";
import { getAuditLogDisplayName } from "../utils/audit_logs";
import { getJobByID } from "./jobs";

/**
 * Get all analytics
 * 
 * @returns {Promise<Array>} List of all analytics
 */
export async function getAnalytics() {
    return await api_consolidator_error_handler("GET", ANALYTICS_ENDPOINT, "items");
}

/**
 * Get an analytic by ID
 * 
 * @param {number} analytic_id Analytic ID
 * @returns {Promise<Object>} Analytic object
 */
export async function getAnalyticByID(analytic_id) {
    return await api_consolidator_error_handler("GET", `${ANALYTICS_ENDPOINT}/${analytic_id}`);
}

/**
 * Set analytic weed pressure to high
 * 
 * @param {number} analytic_id Analytic ID
 */
export async function setWeedPressureHigh(analytic_id) {
    return await api_consolidator_error_handler("POST", `${ANALYTICS_ENDPOINT}/${analytic_id}/weed_pressure_high`);
}

/**
 * Set analytic weed pressure to low
 * 
 * @param {number} analytic_id Analytic ID
 */
export async function setWeedPressureLow(analytic_id) {
    return await api_consolidator_error_handler("POST", `${ANALYTICS_ENDPOINT}/${analytic_id}/weed_pressure_low`);
}

/**
 * Mark pre polygon review as complete
 * 
 * @param {number} analytic_id Analytic ID
 */
export async function markPrePolygonReviewComplete(analytic_id) {
    return await api_consolidator_error_handler("POST", `${ANALYTICS_ENDPOINT}/${analytic_id}/pre_polygon_review_complete`);
}

/**
 * Get all jobs for an analytic
 * 
 * @param {number} analytic_id Analytic ID
 * @returns {Promise<Array>} List of all jobs for the analytic
 */
export async function getJobsByAnalyticID(analytic_id) {
    return await api_consolidator_error_handler("GET", `${ANALYTICS_ENDPOINT}/${analytic_id}/${JOBS_ENDPOINT}`);
}

/** Get all audit logs for an analytic
 * 
 * @param {number} analytic_id Analytic ID
 * @returns {Promise<Array>} List of all audit logs for the analytic
 */
export async function getAuditLogsByAnalyticID(analytic_id) {
    return await api_consolidator_error_handler("GET", `${ANALYTICS_ENDPOINT}/${analytic_id}/${AUDIT_LOGS_ENDPOINT}`);
}

/**
 * Get whether the Pre Polygon Review is complete
 * 
 * @param {number} analytic_id Analytic ID
 * @returns {Promise<boolean>} Whether the Pre Polygon Review is complete
 */
export async function isPrePolygonReviewComplete(analytic_id) {
    const audit_logs = await getAuditLogsByAnalyticID(analytic_id);
    return audit_logs.some(log => getAuditLogDisplayName(log) === AUDIT_LOG_DISPLAY_NAMES.PRE_POLYGON_REVIEW_COMPLETED);
}

/**
 * Mark an analytic as abandoned.
 * 
 * @param {number} analytic_id Analytic ID 
 * @returns {Promise<boolean>} Whether the analytic was successfully marked as abandoned
 */
export async function markAnalyticAsAbandoned(analytic_id) {
    return await api_consolidator_error_handler("PUT", `${ANALYTICS_ENDPOINT}/${analytic_id}/abandon`);
}

/**
 * Patch an analytic
 * 
 * @param {number} analytic_id Analytic ID
 * @param {number} priority Priority to set
 * @param {boolean} allow_external_annotation Whether to allow external annotation
 * @returns {Promise<boolean>} Whether the analytic was successfully patched
 */
export async function patchAnalytic(analytic_id, priority = null, allow_external_annotation = null) {
    let data = {}
    if (priority !== null) {
        data[ANALYTIC_KEYS.ANALYTIC_PRIORITY] = priority;
    }
    if (allow_external_annotation !== null) {
        data[ANALYTIC_KEYS.ALLOW_EXTERNAL_ANNOTATION] = allow_external_annotation;
    }
    return await api_consolidator_error_handler(
        "PATCH", 
        ANALYTICS_ENDPOINT + "/" + analytic_id, 
        null, 
        null,
        data,
    );
}

/**
 * Get analytic by job ID
 * 
 * @param {number} job_id Job ID
 * @returns {Promise<Object>} Analytic object
 */
export async function getAnalyticByJobID(job_id) {
    const job = await getJobByID(job_id);
    return await getAnalyticByID(job[JOB_KEYS.ANALYTIC_ID]);
}
import "../css/Timer.css";
import { Component } from "react";
import { secondsToHMS } from "../utils/display";

class Timer extends Component {
    constructor(props) {
        super(props);

        // Use prop reset_counter to reset the timer
        // This is useful when we want to reset the timer from a parent component

        this.state = {
            elapsed_time: 0,
        };
    }

    componentDidMount () {
        this.timer = setInterval(() => {
            this.setState({ elapsed_time: this.state.elapsed_time + 1 });
        }, 1000);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.reset_counter !== this.props.reset_counter) {
            this.setState({ elapsed_time: 0 });
        }
    }

    render() {
        return (
            <div className="Timer">
                <h3>Elapsed Time: {secondsToHMS(this.state.elapsed_time)}</h3>
            </div>
        );
    }
}

export default Timer;
import Button from '@mui/material/Button';
import { DEFAULT_USER } from '../utils/constants';
import PixelPatchComponent from './PixelPatchComponent';
import { logout } from '../utils/cognito';

class Logout extends PixelPatchComponent {
    constructor(props) {
        super(props);
        // Get current user
        this.user = props.user;
        // Bind functions
        this.logout = this.logout.bind(this);
    }

    /**
     * Log the user out
     * 
     */
    logout = () => {
        logout(this.user);
        this.setUser(DEFAULT_USER, []);
    }

    render() {
        return (
            <div>
                <h1>Logout</h1>
                <h3>{ this.user }</h3>
                <p>Are you sure you want to logout?</p>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={this.logout}
                >
                    Logout
                </Button>
            </div>
        );
    }
}

export default Logout;
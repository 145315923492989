// Annotation utils
import { 
    ANALYTIC_KEYS,
    DEFAULT_USER,
    IMAGE_DISPLAY_STATUSES,
    IMAGE_KEYS,
    IMAGE_STATUSES,
    INITIAL_LINE_SIZE,
    JOB_KEYS, 
    SUBIMAGE_CLASS_ID,
} from "./constants";
import {
    getAnalyticByID, 
    getAnalyticByJobID 
} from "../api/analytics";
import { getImagesByJobID, getJobByID, getNumberOfRemainingImages } from "../api/jobs";
import { getImageDimensions } from "./image";
import { getMostRecentAnnotator } from "../api/images";
import { v4 as uuid } from "uuid";

/**
 * Load an annotation json from a signed URL
 * 
 * @param {string} url Signed URL
 * @returns {Promise<Object>} Annotation JSON
 */
export async function loadAnnotationFromURL(url) {
    const response = await fetch(url);
    let ann = await response.json();
    return ann.annotations;
}

/**
 * Get PixelPatchLabelerProps from an image object
 * 
 * @param {object} image Image object
 * @param {boolean} is_review Whether the user is reviewing
 * @param {boolean} view_only Whether the user is viewing only
 * @param {string} job_type Job type
 * @param {number} analytic_id Analytic ID
 * @param {string} field_name Field name
 * @param {string} org_name Organization name
 * @param {boolean} internal Whether calling as an internal user
 * @param {Array<number>} image_ids Image IDs of all images in the job
 * @param {number} n_remaining_images Number of remaining images (the number displayed in the top bar of the UI)
 * @returns {Promise<Object>} PixelPatchLabelerProps
 */
export async function getPixelPatchLabelerPropsFromImage(image, is_review = false, view_only = false, job_type = null, analytic_id = null, field_name = null, org_name = null, internal = true, image_ids = null, n_remaining_images = null) {
    const image_id = image[IMAGE_KEYS.ID];
    const job_id = image[IMAGE_KEYS.JOB_ID];
    const annotations_promise = loadAnnotationFromURL(image[IMAGE_KEYS.ANNOTATION_URL]);
    const most_recent_annotator_promise = internal ? getMostRecentAnnotator(image_id) : DEFAULT_USER;
    const image_dimensions = await getImageDimensions(image[IMAGE_KEYS.IMAGE_URL]);

    // Get job type, analytic ID, field name, and organization name if not provided
    let analytic = null;
    if (job_type === null && internal) {
        job_type = getJobByID(job_id).then(job => job[JOB_KEYS.JOB_TYPE]);
    } 
    if (analytic_id === null && internal) {
        analytic = getAnalyticByJobID(job_id);
        analytic_id = analytic.then(analytic => analytic[ANALYTIC_KEYS.ID]);
    }
    if (field_name === null && internal) {
        if (analytic === null) {
            analytic = getAnalyticByID(await analytic_id);
        }
        field_name = analytic.then(analytic => analytic[ANALYTIC_KEYS.FIELD_NAME]);
    }
    if (org_name === null && internal) {
        if (analytic === null) {
            analytic = getAnalyticByID(await analytic_id);
        }
        org_name = analytic.then(analytic => analytic[ANALYTIC_KEYS.ORGANIZATION_NAME]);
    }
    // Get image IDs/number of remaining images if not provided
    if (view_only && image_ids === null && internal) {
        // Get all image IDs in the job and sort them by ID
        const images = await getImagesByJobID(job_id, [...IMAGE_DISPLAY_STATUSES, IMAGE_STATUSES.EXCLUDED]);
        image_ids = images.map(image => image[IMAGE_KEYS.ID]).sort((a, b) => a - b);
    } else if (!view_only && n_remaining_images === null) {
        n_remaining_images = getNumberOfRemainingImages(job_id, is_review);
    }
    let props = {
        image_id: image_id,
        image_name: image[IMAGE_KEYS.FILENAME],
        image_status: image[IMAGE_KEYS.IMAGE_STATUS],
        image: image[IMAGE_KEYS.IMAGE_URL],
        image_dimensions: await image_dimensions,
        mask: image[IMAGE_KEYS.MASK_URL],
        job_id: job_id,
        annotations: await annotations_promise,
        most_recent_annotator: await most_recent_annotator_promise,
        job_type: await job_type,
        analytic_id: await analytic_id,
        field_name: await field_name,
        org_name: await org_name,
        internal: internal,
        image_ids: await image_ids,
        is_review: is_review,
        view_only: view_only,
        n_remaining_images: await n_remaining_images,
    }
    return props;
}

/**
 * Creates a classification payload for a subimage. 
 * 
 * @returns {object} The classification payload.
 */
export function get_subimage_class_payload() {
    return [
        {
            class_id: SUBIMAGE_CLASS_ID,
            confidence: 1,
        },
    ]
}

/**
 * Create a ulabel bbox annotation. 
 * @param {number} tl_x Top left x coordinate.
 * @param {number} tl_y Top left y coordinate.
 * @param {number} width Annotation width.
 * @param {number} height Annotation height.
 * @param {object} class_payload Classification payload.
 * @returns {object} The bbox annotation, in ULabel format.
 */
export function make_ulabel_bbox(
    tl_x,
    tl_y,
    width,
    height,
    class_payload = null,
) { 
    if (class_payload === null) { 
        class_payload = get_subimage_class_payload();
    }
    return { 
        id: uuid(),
        new: true,
        created_by: DEFAULT_USER,
        created_at: Date.now(),
        deprecated: false,
        spatial_type: "bbox",
        spatial_payload: [ 
            [ tl_x, tl_y ],
            [ tl_x + width, tl_y + height ],
        ],
        classification_payloads: class_payload,
        line_size: INITIAL_LINE_SIZE,
    }
}

// https://github.com/SenteraLLC/py-mfstand/blob/71bb7d0c96d9ccf761fbb17aeb34653707d5bd7f/frontends/common/src/app/ulabel_anno_utils.js#L142
/**
 * Creates a list of bbox annotations for all subimages in an image, optionally with QA status.
 * @param {number} n_w_subimages number of subimages width-wise.
 * @param {number} n_h_subimages number of subimages height-wise.
 * @param {number} image_width image width.
 * @param {number} image_height image height.
 * @param {number} buffer_pct optional: percentage of the subimage width/height to buffer the crop by, default 0.
 * @param {number} resize_factor optional: multiplied by the subimage width/height to resize it, default 1.
 * @returns {Array<object>} List of bbox annotations for all subimages in the image.
 */
export function make_all_subimage_bboxes(
    n_w_subimages,
    n_h_subimages,
    image_width,
    image_height,
    buffer_pct = 0,
    resize_factor = 0.995, // slightly less than 1 so that the subimages don't overlap
) { 
    const n_subimages = n_w_subimages * n_h_subimages;
    let bboxes = [];
    for (let subimage_idx = 0; subimage_idx < n_subimages; subimage_idx++) { 
        const class_payload = get_subimage_class_payload();

        // Calculate the crop
        const buffer_float = buffer_pct / 100;
        const buffer_x_px = image_width * buffer_float;
        const buffer_y_px = image_height * buffer_float;

        let subimage_width = (image_width - (buffer_x_px * 2)) / n_w_subimages;
        let subimage_height = (image_height - (buffer_y_px * 2)) / n_h_subimages;

        let tl_x = ((subimage_idx % n_w_subimages) * subimage_width) + buffer_x_px;
        let tl_y = (Math.floor(subimage_idx / n_w_subimages) * subimage_height) + buffer_y_px;

        tl_x += (subimage_width * (1 - resize_factor)) / 2;
        tl_y += (subimage_height * (1 - resize_factor)) / 2;
        subimage_width *= resize_factor;
        subimage_height *= resize_factor;

        const crop = [
            tl_x,
            tl_y,
            subimage_width,
            subimage_height,
        ]

        const bbox = make_ulabel_bbox(
            ...crop,
            class_payload
        );
        bboxes.push(bbox);
    }
    return bboxes;
}
// Utils functions for clipboard

/**
 * Copy text to clipboard
 * 
 * @param {string} text - Text to copy
 */
export const copyToClipboard = (text) => {
    let textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
}
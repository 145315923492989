import { AuthenticationDetails, CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import axios from "axios";

const poolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_CLIENT_ID
};
export const UserPool = new CognitoUserPool(poolData);

/**
 * Register a user with Cognito
 * 
 * @param {string} email email address
 * @param {string} password password
 * @returns {Promise} Promise that resolves with the result of the registration
 */
export async function register (email, password) {
    return new Promise((resolve, reject) => {
        // TODO: Add user to default group
        UserPool.signUp(email, password, [], null, (err, result) => {
            if (err) {
                reject(err);
                return;
            }
            resolve(result);
        });
    });
}

/**
 * Confirm a user with Cognito by prompting the user for the confirmation code until it is successful
 * 
 * @param {string} email email address
 * @param {string} password password
 * @param {string} code confirmation code
 * @returns {Promise} Promise that resolves with the result of the confirmation
 */
export async function confirm (email, password, code) {
    const user = new CognitoUser({
        Username: email,
        Pool: UserPool
    });
    return new Promise((resolve) => {
        user.confirmRegistration(code, true, (err, result) => {
            if (err) {
                return resolve(false);
            }
            // Authenticate the user to get the token
            authenticate(email, password).then((result) => {
                return resolve(true);
            });
        });
    });
}

/**
 * Authenticate a user with Cognito
 * 
 * @param {string} email email address
 * @param {string} password password
 * @returns {Promise} Promise that resolves with the result of the authentication
 */
export async function authenticate (email, password) {
    return new Promise((resolve, reject) => {
        const user = new CognitoUser({
            Username: email,
            Pool: UserPool
        });

        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        });

        user.authenticateUser(authDetails,{
            onSuccess:(result) => {
                // Save the token for future API calls
                axios.defaults.headers.common.Authorization = `Bearer ${result.accessToken.jwtToken}`;
                // Store in local storage
                localStorage.setItem("auth", `Bearer ${result.accessToken.jwtToken}`);
                localStorage.setItem("user", email);
                localStorage.setItem(
                    "user_groups",
                    // If no groups are returned, set to empty array
                    result.idToken.payload["cognito:groups"] === undefined 
                        ? JSON.stringify([])
                        : JSON.stringify(result.idToken.payload["cognito:groups"])
                );
                resolve(result);
            },
            onFailure:(err) => {
                reject(err);
            }
        });
    });
};

/**
 * Reset a user's password
 * 
 * @param {string} email email address
 * @param {string} new_password new password
 * @returns {Promise} Promise that resolves with the result of the password reset 
 */
export async function resetPassword (email, new_password) {
    return new Promise((resolve, reject) => {
        const user = new CognitoUser({
            Username: email,
            Pool: UserPool
        });
        user.forgotPassword({
            onSuccess: (result) => {
                resolve(result);
            },
            onFailure: (err) => {
                reject(err);
            },
            inputVerificationCode: function() {
                const verificationCode = prompt("Please input verification code sent to " + email + ": ");
                user.confirmPassword(verificationCode, new_password, this);
            }
        });
    });
}

/**
 * Log the user out
 * 
 * @param {string} email email address
 */
export function logout (email) {
    const user = new CognitoUser({
        Username: email,
        Pool: UserPool
    });
    user.signOut();
    // Remove the token and user from local storage
    localStorage.removeItem("auth");
    localStorage.removeItem("user");
    localStorage.removeItem("user_groups");
};
import "../css/PixelPatchLabeler.css";
import {
    COMPONENTS,
    DEFAULT_USER,
    IMAGE_KEYS,
    IMAGE_STATUSES,
    INITIAL_LINE_SIZE,
    JOB_TYPES, 
    POINT_QA_SUBTASKS, 
    POLYGON_QA_SUBTASKS,
    SUBIMAGE_SUBTASK_KEY,
    ULABEL_CONFIG_DATA, 
} from "../utils/constants";
import { getImageByID, getNextImageToAnnotate } from "../api/images";
import { getMostRecentAnnotator, saveAnnotations, setImageStatus, unlockImage } from "../api/images";
import { getNextImage, getNumberOfRemainingImages } from "../api/jobs";
import { getPixelPatchLabelerPropsFromImage, loadAnnotationFromURL, make_all_subimage_bboxes } from "../utils/annotation";
import PixelPatchComponent from "./PixelPatchComponent";
import Timer from "./Timer";
import Ulabel from "./Ulabel";
import { getImageDimensions } from "../utils/image";
import { makeDisplayString } from "../utils/display";

class PixelPatchLabeler extends PixelPatchComponent {
    image_ids_to_unlock = [];

    constructor(props) {
        super(props);

        // Initialize props
        if (props.job_type === undefined) {
            this.showToast("Invalid props. Cannot return to a labeling job using the back/forward buttons. Please navigate to the jobs page.", 5000);
        } else {
            this.subtasks = props.job_type === JOB_TYPES.POINT_LABELING ? POINT_QA_SUBTASKS : POLYGON_QA_SUBTASKS;
            this.job_id = props.job_id;
        }

        this.internal = props.internal;
        this.analytic_id = props.analytic_id;
        this.field_name = props.field_name;
        this.org_name = props.org_name;
        this.job_type = props.job_type;

        // For skipping images, we track what images we need to unlock before leaving.
        if (!props.image_id !== undefined && !props.view_only) {
            this.image_ids_to_unlock.push(props.image_id);
        }

        // Init state
        this.state = {
            image_id: props.image_id,
            image_name: props.image_name,
            image_status: props.image_status,
            image: props.image,
            image_dimensions: props.image_dimensions,
            mask: props.mask,
            annotations: props.annotations,
            user: props.user,
            is_review: props.is_review || false,
            view_only: props.view_only || false,
            image_ids: props.image_ids,
            n_remaining_images: props.n_remaining_images,
            most_recent_annotator: props.most_recent_annotator || DEFAULT_USER,
            reset_counter: 0,
        };

        // Bind functions
        this.getAnnotations = this.getAnnotations.bind(this);
        this.unlockAllImages = this.unlockAllImages.bind(this);
        this.navigateAway = this.navigateAway.bind(this);
        this.getAndSetNextImage = this.getAndSetNextImage.bind(this);
        this.saveAnnotationsAndChangeImageStatus = this.saveAnnotationsAndChangeImageStatus.bind(this);
        this.getSubmitButtons = this.getSubmitButtons.bind(this);
    }

    componentDidMount () {
        // Hide the menu to force users to use the submit buttons, which will properly handle navigation
        const menu = document.getElementById("menu");
        menu.style.display = "none";
    }

    componentWillUnmount = async () => {
        // Show the menu when the component unmounts
        const menu = document.getElementById("menu");
        // Remove the display style to revert to the default
        menu.style.removeProperty("display");

        // Unlock any images that need to be unlocked
        if (this.image_ids_to_unlock.length > 0) {
            await this.unlockAllImages();
        }
        // Reset global props to prevent the back button from navigating back to the image
        this.resetGlobalProps(COMPONENTS.PIXEL_PATCH_LABELER);
    }

    /**
     * Get the annotations for the current image, adding the subimage bounds if needed
     * 
     * @returns {object} the annotations for the current image.
     */
    getAnnotations = () => { 
        if (Object.keys(this.subtasks).includes(SUBIMAGE_SUBTASK_KEY)) {
            // Construct a 3x3 grid of subimage bboxes
            const subimage_bboxes = make_all_subimage_bboxes(
                3,
                3,
                this.state.image_dimensions.width,
                this.state.image_dimensions.height,
            );
            // Add the subimage bboxes to the annotations
            return {
                ...this.state.annotations,
                [SUBIMAGE_SUBTASK_KEY]: subimage_bboxes,
            }
        } else {
            return this.state.annotations;
        }
    }

    /**
     * Get the number of weed and crop annotations from the ULabel Toolbox Class Counter
     * 
     * @returns {object} the number of weed and crop annotations {"weed_annotation_count": int, "crop_annotation_count": int}
     */
    getNumberOfWeedAndCropAnnotations = () => {
        const annotation_counts_str = document.getElementsByClassName("toolbox-class-counter")[0].lastChild.innerHTML;
        // Weed: 7<br>Crop: 14<br> or Weed: 7<br>
        const annotation_counts = annotation_counts_str.split("<br>");
        let weed_annotation_count = 0;
        let crop_annotation_count = 0;
        for (let count_str of annotation_counts) {
            if (count_str.includes("Weed")) {
                weed_annotation_count = parseInt(count_str.split("Weed: ")[1]);
            } else if (count_str.includes("Crop")) {
                crop_annotation_count = parseInt(count_str.split("Crop: ")[1]);
            }
        }

        return {
            "weed_annotation_count": weed_annotation_count, 
            "crop_annotation_count": crop_annotation_count
        };
    }

    /**
     * Unlock all images that need to be unlocked before leaving.
     */
    unlockAllImages = async () => {
        const unlock_promises = this.image_ids_to_unlock.map((image_id) => unlockImage(image_id));
        await Promise.all(unlock_promises);
    }

    /**
     * Navigate to the jobs page.
     * 
     */
    navigateAway = async () => {
        if (this.internal) {
            // Navigate to the jobs page
            this.navigateTo(
                COMPONENTS.JOBS,
                { 
                    analytic_id: this.analytic_id, 
                    field_name: this.field_name,
                    org_name: this.org_name,
                    job_type: this.job_type,
                }
            )
        } else {
            // Navigate to the external homepage
            this.navigateTo(COMPONENTS.EXTERNAL_HOMEPAGE);
        }
    }

    /**
     * Get the next image to label and set the state.
     *  
     */
    getAndSetNextImage = async () => {
        let next_image = null;
        if (this.internal) {
            next_image = await getNextImage(this.job_id, this.state.is_review);
        } else {
            next_image = await getNextImageToAnnotate(this.job_type);
        }

        if (next_image === null || next_image[IMAGE_KEYS.IMAGE_URL] === undefined) {
            // There are no more images to label.
            this.navigateAway();
        } else {
            const n_remaining_images_promise = getNumberOfRemainingImages(this.job_id, this.state.is_review);
            const annotations_promise = loadAnnotationFromURL(next_image[IMAGE_KEYS.ANNOTATION_URL]);
            const most_recent_annotator_promise = this.state.is_review ? getMostRecentAnnotator(next_image[IMAGE_KEYS.ID]) : DEFAULT_USER
            const image_dimensions = getImageDimensions(next_image[IMAGE_KEYS.IMAGE_URL]);
            this.image_ids_to_unlock.push(next_image[IMAGE_KEYS.ID]);
            this.setState({
                image_id: next_image[IMAGE_KEYS.ID],
                image_name: next_image[IMAGE_KEYS.FILENAME],
                image_status: next_image[IMAGE_KEYS.IMAGE_STATUS],
                image: next_image[IMAGE_KEYS.IMAGE_URL],
                image_dimensions: await image_dimensions,
                mask: next_image[IMAGE_KEYS.MASK_URL],
                annotations: await annotations_promise,
                most_recent_annotator: await most_recent_annotator_promise,
                reset_counter: this.state.reset_counter + 1,
                n_remaining_images: await n_remaining_images_promise,
            });
        }
    }

    /**
     * Save the annotations for the image and change the image status.
     * 
     * @param {*} annotations ulabel annotations
     * @param {*} image_status the new image status
     */
    saveAnnotationsAndChangeImageStatus = async (annotations, image_status) => {
        // Save the annotations
        const annotation_counts = this.getNumberOfWeedAndCropAnnotations();
        await saveAnnotations(this.state.image_id, annotations, annotation_counts.weed_annotation_count, annotation_counts.crop_annotation_count);
        // Change the image status
        await setImageStatus(this.state.image_id, image_status);
        // Remove the image from the list of images to unlock
        this.image_ids_to_unlock = this.image_ids_to_unlock.filter((image_id) => image_id !== this.state.image_id);
    }

    // ================= Submit Button Hooks =================
    /**
     * Save annotations and continue to the next image.
     * 
     * @param {*} annotations ulabel annotations 
     */
    saveAndContinue = async (annotations) => {
        const image_status = this.state.is_review ? IMAGE_STATUSES.COMPLETE : IMAGE_STATUSES.READY_TO_REVIEW;
        await this.saveAnnotationsAndChangeImageStatus(annotations, image_status);
        await this.getAndSetNextImage();
    }

    /**
     * Exit the labeling tool without saving annotations.
     *
     * @param {*} annotations ulabel annotations
     */
    exitWithoutSaving = async (annotations) => {
        // Prompt the user to confirm they want to exit without saving
        if (!await this.confirm("Are you sure you want to exit without saving?")) {
            // Add back the annotations if the user cancels
            this.setState({annotations: annotations.annotations});
            return;
        }
        // Unlock
        if (!await unlockImage(this.state.image_id)) {
            this.showToast("Failed to unlock image. Please try again.");
            // Add back the annotations if the unlock failed.
            this.setState({annotations: annotations.annotations});
            return;
        }
        // Remove the image from the list of images to unlock
        this.image_ids_to_unlock = this.image_ids_to_unlock.filter((image_id) => image_id !== this.state.image_id);
        // Navigate to the jobs page
        // ULabel will handle warning the user about unsaved changes
        this.navigateAway();
    }

    /**
     * Reject the image and continue to the next image.
     * 
     * @param {*} annotations ulabel annotations
     */
    rejectAndContinue = async (annotations) => {
        await this.saveAnnotationsAndChangeImageStatus(annotations, IMAGE_STATUSES.READY_TO_ANNOTATE);
        await this.getAndSetNextImage();
    }

    /**
     * Skip the image and continue to the next image.
     * 
     * @param {*} annotations ulabel annotations 
     */
    skipAndContinue = async (annotations) => {
        // Prompt the user to confirm they want to skip the image
        if (!await this.confirm("Are you sure you want to skip this image?")) {
            // Add back the annotations if the user cancels
            this.setState({annotations: annotations.annotations});
            return;
        }
        // Store the image id to unlock before leaving
        this.image_ids_to_unlock.push(this.state.image_id);
        await this.getAndSetNextImage(false);
    }

    /**
     * Exclude the image and continue to the next image.
     * 
     * @param {*} annotations ulabel annotations 
     */
    excludeAndContinue = async (annotations) => {
        // Prompt the user to confirm they want to exclude the image
        if (!await this.confirm("Are you sure you want to exclude this image from the dataset?")) {
            // Add back the annotations if the user cancels
            this.setState({annotations: annotations.annotations});
            return;
        }
        // First we unlock the image by moving it to READY_TO_ANNOTATE
        await unlockImage(this.state.image_id);
        // Then we exclude the image
        await setImageStatus(this.state.image_id, IMAGE_STATUSES.EXCLUDED);
        // Remove the image from the list of images to unlock
        this.image_ids_to_unlock = this.image_ids_to_unlock.filter((image_id) => image_id !== this.state.image_id);
        // Get the next image
        await this.getAndSetNextImage();
    }

    /**
     * Continue to the next image in view only mode.
     */
    continueViewOnly = async (annotations, get_previous = false) => {
        // Get the next image id in the list of image ids
        const current_image_id = this.state.image_id;
        let next_idx = this.state.image_ids.indexOf(current_image_id);
        next_idx += get_previous ? -1 : 1;

        // Check if there are more images to view
        if (next_idx >= this.state.image_ids.length || next_idx < 0) {
            // There are no more images to view
            return this.navigateAway();
        }

        // Get the next image
        const next_image_id = this.state.image_ids[next_idx];
        const next_image = await getImageByID(next_image_id);
        let props = await getPixelPatchLabelerPropsFromImage(
            next_image,
            this.state.is_review,
            this.state.view_only, 
            this.job_type,
            this.analytic_id,
            this.field_name,
            this.org_name,
            this.internal,
            this.state.image_ids,
        );
        // Update the state
        this.setState({
            image_id: next_image_id,
            image_name: props.image_name,
            image_status: props.image_status,
            image: props.image,
            mask: props.mask,
            annotations: props.annotations,
            is_review: props.most_recent_annotator !== "",
            most_recent_annotator: props.most_recent_annotator,
            reset_counter: this.state.reset_counter + 1,
        });
    }

    /**
     * Wrapper for continueViewOnly to get the previous image.
     */
    previousViewOnly = async (annotations) => {
        this.continueViewOnly(annotations, true);
    }

    /**
     * Get the submit buttons based on the current state.
     * 
     * @returns an array of submit buttons based on the current state.
     */
    getSubmitButtons = () => {
        if (this.state.view_only) {
            // Viewing buttons
            return [
                {
                    "name": "Next Image",
                    "hook": this.continueViewOnly,
                    "color": "lightgray",
                    "set_saved": true,
                },
                {
                    "name": "Previous Image",
                    "hook": this.previousViewOnly,
                    "color": "gray",
                    "set_saved": true,
                },
                {
                    "name": "Exit",
                    "hook": this.navigateAway,
                    "color": "khaki",
                    "set_saved": true,
                }
            ]
        } else if (this.state.is_review) {
            // Review buttons
            return [
                {
                    "name": "Approve",
                    "hook": this.saveAndContinue,
                    "color": "lightgreen",
                    "set_saved": true,
                },
                {
                    "name": "Re-Annotate",
                    "hook": this.rejectAndContinue,
                    "color": "lightblue",
                    "set_saved": true,
                },
                {
                    "name": "Exit Without Saving",
                    "hook": this.exitWithoutSaving,
                    "color": "khaki",
                    "set_saved": true,
                }, 
                {
                    "name": "Exclude",
                    "hook": this.excludeAndContinue,
                    "color": "pink",
                    "set_saved": true,
                }
            ]
        } else {
            // Annotation buttons
            let ret = [
                {
                    "name": "Submit",
                    "hook": this.saveAndContinue,
                    "color": "lightgreen",
                    "set_saved": true,
                },
                {
                    "name": "Exit Without Saving",
                    "hook": this.exitWithoutSaving,
                    "color": "khaki",
                    "set_saved": true,
                },
            ]
            if (this.internal) {
                // Add the exlude button if internal
                ret.push(
                    {
                        "name": "Exclude",
                        "hook": this.excludeAndContinue,
                        "color": "pink",
                        "set_saved": true,
                    }
                )
            }
            return ret;
        }
    }


    render() {
        return (
            <div className="PixelPatchLabeler" key={this.component_updater}>
                <div className="header-info-container">
                    {/* Show image name */}
                    {this.internal && <h3>{this.org_name} / {this.field_name} / {this.state.image_name}</h3>}
                    {/* If viewing, show image status */}
                    {this.state.view_only && 
                        <h3> 
                            <div className={this.state.image_status}>
                                { makeDisplayString(this.state.image_status) }
                            </div>
                        </h3>
                    }
                    {/* If reviewing, show annotator name */}
                    {this.state.is_review && <h3>Reviewing: {this.state.most_recent_annotator}</h3>}
                    {/* If viewing, show image # / total */}
                    {this.state.view_only && <h3>Image {this.state.image_ids.indexOf(this.state.image_id) + 1} / {this.state.image_ids.length}</h3>}
                    {/* If NOT viewing, show # images remaining */}
                    {!this.state.view_only && <h3>Images Remaining to {this.state.is_review ? "Review" : "Annotate"}: {this.state.n_remaining_images}</h3>}
                    {/* Show elapsed time, and reset when loading a new image */}
                    {this.state.view_only 
                        ? <h3>View (Read Only)</h3> 
                        : <Timer reset_counter = {this.state.reset_counter}/>
                    }
                </div>
                {this.state.image_name !== undefined &&
                    <Ulabel
                        image = {this.state.image} // Use state so changes trigger child "componentDidUpdate" hook
                        mask = {this.state.mask}
                        subtasks = {this.subtasks}
                        annotations = {this.getAnnotations()}
                        username = {this.state.user}
                        other_ulabel_props = {{
                            "config_data": ULABEL_CONFIG_DATA,
                            "initial_line_size": INITIAL_LINE_SIZE,
                            "submit_buttons": this.getSubmitButtons(),
                        }}
                    />
                }
            </div>
        )
    }
}

export default PixelPatchLabeler;
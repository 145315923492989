import "../css/Home.css";
import { PIXEL_PATCH_VERSION } from "../version.js";
import PixelPatchComponent from "./PixelPatchComponent";
import logo from "../assets/sentera_logo.png";

class Home extends PixelPatchComponent {
    render() {
        return (
            <div className="Home">
                <header className="Home-header">
                    <img src={logo} className="Home-logo" alt="logo" />
                    <p>
                        Welcome to Pixel Patch v{PIXEL_PATCH_VERSION}
                    </p>
                </header>
            </div>
        )
    }
}

export default Home;
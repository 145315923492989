// API for interacting with Jobs
import { 
    AUDIT_LOGS_ENDPOINT,
    IMAGES_ENDPOINT, 
    IMAGE_KEYS,
    IMAGE_STATUSES,
    JOBS_ENDPOINT 
} from "../utils/constants";
import { api_consolidator_error_handler } from "../utils/api_utils";

/**
 * Get all jobs
 * 
 * @returns {Promise<Array>} List of all jobs
 */
export async function getJobs() {
    return await api_consolidator_error_handler("GET", JOBS_ENDPOINT, "items");
}

/** 
 * Get the job details by analytic id and job id
 * 
 * @param {number} job_id Job ID
 * @returns {Promise<Object>} Job details
 */
export async function getJobByID(job_id) {
    if (job_id === undefined) {
        return {completed: false};
    }
    return await api_consolidator_error_handler("GET", `${JOBS_ENDPOINT}/${job_id}`);
}

/**
 * Mark a job as complete
 * 
 * @param {number} job_id Job ID
 * @param {boolean} do_fine_tuning Whether to do fine tuning or not
 * @returns {Promise<Object>} Success or error message
 */
export async function completeJob(job_id, do_fine_tuning = null) {
    return await api_consolidator_error_handler("POST", `${JOBS_ENDPOINT}/${job_id}/complete`, null, {do_fine_tuning: do_fine_tuning});
}

/**
 * Get all images for a job
 * 
 * @param {number} job_id Job ID
 * @param {Array} image_statuses List of image statuses to filter by
 * @returns {Promise<Array>} List of all images for the job
 */
export async function getImagesByJobID(job_id, image_statuses = null) {
    return await api_consolidator_error_handler("GET", `${JOBS_ENDPOINT}/${job_id}/${IMAGES_ENDPOINT}`, "items", {image_status: image_statuses});
}

/** Get several image data fields organized by image name
 * 
 * @param {number} job_id Job ID
 * @returns {Array<object>} [{image_name: rgb_url, ...}, {image_name: mask_url, ...}, {image_name: image_status, ...}, {image_name: image_id, ...}]
 */
export async function getImageDataByImageName(job_id) {
    let images = await getImagesByJobID(job_id);
    let rgb_urls = {};
    let mask_urls = {};
    let annotation_overlay_urls = {};
    let image_statuses = {};
    let image_ids = {};
    
    // Create dictionaries with image name as keys
    if (images) {
        images.forEach((image) => {
            // Remove the filename extension
            let filename = image.filename.split(".")[0];
            rgb_urls[filename] = image[IMAGE_KEYS.IMAGE_URL];
            mask_urls[filename] = image[IMAGE_KEYS.MASK_URL];
            annotation_overlay_urls[filename] = image?.[IMAGE_KEYS.ANNOTATION_OVERLAY_URL];
            image_statuses[filename] = image[IMAGE_KEYS.IMAGE_STATUS];
            image_ids[filename] = image[IMAGE_KEYS.ID];
        });
    }
    return [rgb_urls, mask_urls, annotation_overlay_urls, image_statuses, image_ids];
}

/**
 * Get audit logs for a job
 * 
 * @param {number} job_id Job ID
 * @returns {Promise<Array>} List of all audit logs for the job
 */
export async function getAuditLogsByJobID(job_id) {
    return await api_consolidator_error_handler("GET", `${JOBS_ENDPOINT}/${job_id}/${AUDIT_LOGS_ENDPOINT}`);
}

/**
 * Get all image audit logs for a job
 * 
 * @param {number} job_id Job ID
 * @returns {Promise<Array>} List of all image audit logs for the job
 */
export async function getImageAuditLogsByJobID(job_id) {
    return await api_consolidator_error_handler("GET", `${JOBS_ENDPOINT}/${job_id}/${IMAGES_ENDPOINT}/${AUDIT_LOGS_ENDPOINT}`);
}

/**
 * Get next image to label
 * 
 * @param {number} job_id Job ID
 * @param {boolean} is_review Is this a review job?
 * @returns {Promise<Object>} Image object for next available image, which could be null
 */
export async function getNextImage(job_id, is_review) {
    if (is_review) {
        return await api_consolidator_error_handler("POST", `${JOBS_ENDPOINT}/${job_id}/next_review_image`);
    } else {
        return await api_consolidator_error_handler("POST", `${JOBS_ENDPOINT}/${job_id}/next_annotate_image`);
    }
}

/**
 * Get the number of remaining images for a job (the number displayed in the top bar of the UI)
 * 
 * @param {number} job_id Job ID
 * @param {boolean} is_review Whether the user is reviewing
 * @returns {number} Number of remaining images
 */
export async function getNumberOfRemainingImages(job_id, is_review = false) {
    let image_statuses = [IMAGE_STATUSES.READY_TO_ANNOTATE];
    if (is_review) {
        image_statuses = [IMAGE_STATUSES.READY_TO_REVIEW];
    } 
    const images = await getImagesByJobID(job_id, image_statuses);
    return images.length;
}
import "../css/Grid.css";
import { 
    COMPONENTS,
    DEFAULT_N_TILES,
    GRID_KEYBINDS, 
    GRID_KEYBIND_NAMES, 
    GRID_MODES,
    IMAGE_STATUSES,
    JOB_KEYS,
    JOB_TYPES,
} from "../utils/constants";
import { 
    getJobsByAnalyticID,
    markPrePolygonReviewComplete, 
    setWeedPressureHigh, 
    setWeedPressureLow 
} from "../api/analytics";
import { includeImage, setImageStatus } from "../api/images";
import { 
    isHorizontalScrollbarVisible, 
    toggle_refresh_icon_by_id, 
    update_all_overlays 
} from "../utils/display";
import $ from "jquery";
import CachedIcon from "@mui/icons-material/Cached";
import { Fragment } from "react";
import GridImage from "./GridImage";
import PixelPatchComponent from "./PixelPatchComponent";
import { getImageDataByImageName } from "../api/jobs";
import { get_keybind_in_keypress_event } from "../utils/keybind_utils";
import x_overlay_red from "../assets/x_red.svg";

class WeedPressure extends PixelPatchComponent {
    state = {
        image_names: [],
        images: {}, // {image_name: rgb_url, ...}
        annotation_overlay_urls: {}, // {image_name: annotation_overlay_url, ...}
        image_stack: [], // [ {image_name: mask_url, ...} ]
        image_statuses: {}, // {image_name: image_status, ...}
        image_ids: {}, // {image_name: image_id, ...}
        labels: {},
    }
    component_updater = 0;
    grid_refresh_icon_id = "grid-refresh-icon";
    n_selected_images = 0;
    n_labeling_images = 0;
    grid_width_percent = 100;

    constructor(props) {
        super(props);

        // Init props
        this.field_name = props.field_name;
        this.org_name = props.org_name;
        this.analytic_id = props.analytic_id || null;
        this.grid_mode = props.grid_mode;
        this.job = props.job || null;
        this.grid_width = props.grid_width || 1;
        this.hover_image_id = null;
        this.allow_next_scroll = false;
        this.current_image_layer_idx = 0; // 0 is the original image
        this.annotation_overlays_visible = true;
        this.show_previously_excluded = false;
        this.show_completed = false;
        this.show_new_tiles_only = false;
        this.og_labels = {};

        this.classes = [
            {
                "class_name": IMAGE_STATUSES.NOT_USED,
                "svg_overlay": null
            }, 
            {
                "class_name": IMAGE_STATUSES.READY_TO_ANNOTATE,
                "svg_overlay": null
            }, 
            {
                "class_name": IMAGE_STATUSES.ANNOTATING,
                "svg_overlay": null
            },
            {
                "class_name": IMAGE_STATUSES.READY_TO_REVIEW,
                "svg_overlay": null
            },
            {
                "class_name": IMAGE_STATUSES.REVIEWING,
                "svg_overlay": null
            },
            {
                "class_name": IMAGE_STATUSES.COMPLETE,
                "svg_overlay": null
            },
            {
                "class_name": IMAGE_STATUSES.EXCLUDED,
                "svg_overlay": x_overlay_red
            }
        ]

        // Get overlay info
        this.initOverlays();

        // Bind functions
        this.updateGridWrapper = this.updateGridWrapper.bind(this);
        this.updateGrid = this.updateGrid.bind(this);
        this.setNumberOfLabelingImages = this.setNumberOfLabelingImages.bind(this);
        this.mousemoveEventHandler = this.mousemoveEventHandler.bind(this);
        this.scrollEventHandler = this.scrollEventHandler.bind(this);
        this.keydownEventHandler = this.keydownEventHandler.bind(this);
        this.getSortedImageNames = this.getSortedImageNames.bind(this);
        this.changeImage = this.changeImage.bind(this);
        this.changeAllImages = this.changeAllImages.bind(this);
        this.toggleAnnotationOverlay = this.toggleAnnotationOverlay.bind(this);
        this.toggleAllAnnotationOverlays = this.toggleAllAnnotationOverlays.bind(this);
        this.initOverlays = this.initOverlays.bind(this);
        this.autoScroll = this.autoScroll.bind(this);
        this.getImageStackByName = this.getImageStackByName.bind(this);
        this.getClassFromImageName = this.getClassFromImageName.bind(this);
        this.getInitLabels = this.getInitLabels.bind(this);
        this.updateLocalLabels = this.updateLocalLabels.bind(this);
        this.clearAllChanges = this.clearAllChanges.bind(this);
        this.saveLabels = this.saveLabels.bind(this);
        this.changeGridWidth = this.changeGridWidth.bind(this);
        this.changeImageSize = this.changeImageSize.bind(this);
        this.changeSelectedImages = this.changeSelectedImages.bind(this);
        this.handleWeedPressureForm = this.handleWeedPressureForm.bind(this);
        this.handlePrePolygonReviewComplete = this.handlePrePolygonReviewComplete.bind(this);
        this.handleManageImagesSubmit = this.handleManageImagesSubmit.bind(this);
    }

    /**
     * Update the grid and show the refresh icon while updating
     */
    async updateGridWrapper() {
        toggle_refresh_icon_by_id(this.grid_refresh_icon_id, true);
        await this.updateGrid();
        toggle_refresh_icon_by_id(this.grid_refresh_icon_id, false);
    }

    /**
     * Fetch images from the API and set the state with the images and image names
     */
    async updateGrid() {
        if (this.analytic_id === null) {
            // Abandon the component if the analytic_id is not provided
            this.navigateTo(COMPONENTS.ANALYTICS);
        }

        if (this.job === null) {
            let jobs;
            switch (this.grid_mode) {
                case GRID_MODES.WEED_PRESSURE_DECISION:
                    jobs = await getJobsByAnalyticID(this.analytic_id)
                    // Get the JOB_TYPES.POINT_LABELING job
                    this.job = jobs.find(job => job[JOB_KEYS.JOB_TYPE] === JOB_TYPES.POINT_LABELING);
                    break;
                case GRID_MODES.PRE_POLYGON_REVIEW:
                    jobs = await getJobsByAnalyticID(this.analytic_id)
                    // Get the JOB_TYPES.POLYGON_LABELING job
                    this.job = jobs.find(job => job[JOB_KEYS.JOB_TYPE] === JOB_TYPES.POLYGON_LABELING);
                    break;
                default:
                    // Abandon the component if the job is not provided
                    this.navigateTo(COMPONENTS.ANALYTICS);
                    break;
            }
        }

        // Get the job_id from the job object
        const job_id = this.job[JOB_KEYS.ID];
        // Fetch images from the API
        const [rgb_urls, mask_urls, annotation_overlay_urls, image_statuses, image_ids] = await getImageDataByImageName(job_id);   
        const image_names = this.getSortedImageNames(rgb_urls);
        // Initialize labels
        const labels = this.getInitLabels(image_names, image_statuses);
        // Save the original labels
        this.og_labels = JSON.parse(JSON.stringify(labels));
        this.setState({
            images: rgb_urls,
            image_stack: [mask_urls],
            annotation_overlay_urls: annotation_overlay_urls,
            image_statuses: image_statuses,
            image_ids: image_ids,
            image_names: image_names,
            labels: labels,
        });
        this.component_updater++;
    }

    /**
     * Set the number of images selected for labeling (all selected, non-excluded images)
     */
    setNumberOfLabelingImages = () => {
        // Start with the number of selected images, and subtract any that are excluded
        const labels = this.updateLocalLabels(false);
        this.n_labeling_images = JSON.parse(JSON.stringify(this.n_selected_images));
        for (let idx = 0; idx < this.n_selected_images; idx++) {
            let image_name = this.state.image_names[idx];
            if (labels[image_name]["class"] === IMAGE_STATUSES.EXCLUDED) {
                this.n_labeling_images--;
            }
        }
        // Display the number of labeling images
        document.getElementById("n-labeling-images").innerText = this.n_labeling_images + " Images Selected for Labeling";
    }

    /**
     * Init grid and add event listeners
     */
    async componentDidMount() {
        this.updateGridWrapper();
        // Add all event listeners
        this.addAllEventListeners();
    }

    /**
     * Remove all event listeners before the component is unmounted
     */
    componentWillUnmount() {
        // Remove all event listeners
        this.removeAllEventListeners();
    }

    componentDidUpdate() {
        // Check if the horizontal scrollbar is visible
        if (isHorizontalScrollbarVisible()) {
            // If the scrollbar is visible, decrease the image size
            this.changeImageSize(1/1.1);
        } else {
            // Resize all overlays
            update_all_overlays(this);
        }

        // Ensure only selected images are visible
        // Set the number of images to process
        const n_selected_images = this.getNumberOfSelectedImages(this.state.image_statuses);
        this.changeSelectedImages(n_selected_images);
        // Set starting value of the input
        document.getElementById("change-n-images").value = n_selected_images;
    }

    /**
     * Add all event listeners to the document.
     */
    addAllEventListeners = () => {
        // Add event listeners
        document.addEventListener("mousemove", this.mousemoveEventHandler);
        document.addEventListener("scroll", this.scrollEventHandler);
        document.addEventListener("keydown", this.keydownEventHandler);
    }

    /**
     * Remove all event listeners from the document.
     */
    removeAllEventListeners = () => {
        // Remove event listeners
        document.removeEventListener("mousemove", this.mousemoveEventHandler);
        document.removeEventListener("scroll", this.scrollEventHandler);
        document.removeEventListener("keydown", this.keydownEventHandler);
    }
    
    /**
     * Handler for mousemove event listeners.
     * 
     * @param {object} e mousemove event
     */
    mousemoveEventHandler = (e) => {
        // Update which image is currently being hovered
        try {
            if (e.target.className.includes("hover-target")) {
                // Every single hover-target will be inside of a div that's 
                // inside of a div, that has the id that we're trying to select.
                this.hover_image_id = e.target.parentNode.parentNode.id;
            } else {
                this.hover_image_id = null;
            }
        } catch (e) {
            this.hover_image_id = null;
        }
    }

    /**
     * Handler for scroll event listeners.
     */
    scrollEventHandler = () => {
        if (this.allow_next_scroll) {
            this.allow_next_scroll = false;
        } else {
            this.hover_image_id = null;
        }
    }

    /**
     * Handler for keydown event listeners.
     * 
     * @param {object} e keydown event
     */
    keydownEventHandler = (e) => {
        // Keybinds
        switch (get_keybind_in_keypress_event(GRID_KEYBINDS, e)) {
            case GRID_KEYBIND_NAMES.TOGGLE_IMAGE_LAYER:
                this.changeImage(this.hover_image_id);
                break;
            case GRID_KEYBIND_NAMES.TOGGLE_ALL_IMAGE_LAYERS:
                this.changeAllImages();
                break;
            case GRID_KEYBIND_NAMES.NEXT_ROW:
                this.autoScroll(this.hover_image_id, GRID_KEYBIND_NAMES.NEXT_ROW);
                break;
            case GRID_KEYBIND_NAMES.PREV_ROW:
                this.autoScroll(this.hover_image_id, GRID_KEYBIND_NAMES.PREV_ROW);
                break;
            case GRID_KEYBIND_NAMES.INCREASE_IMAGE_SIZE:
                this.changeImageSize(1.1);
                break;
            case GRID_KEYBIND_NAMES.DECREASE_IMAGE_SIZE:
                this.changeImageSize(1/1.1);
                break;
            case GRID_KEYBIND_NAMES.TOGGLE_ANNOTATION_OVERLAY:
                this.toggleAnnotationOverlay(this.hover_image_id);
                break;
            case GRID_KEYBIND_NAMES.TOGGLE_ALL_ANNOTATION_OVERLAYS:
                this.toggleAllAnnotationOverlays();
                break;
            default:
                break;
        }
    }

    /**
     * Images are sorted by name, in groups based on the last `_#` in the name
     * Several tiles are generated from a single source image. The first tile will have the same name as the source image, 
     * and the subsequent tiles will have a `_#` appended to the end, where `#` is the tile number.
     * We want to sort the images by tile number.
     * 
     * @param {object} rgb_urls {image_name: rgb_url, ...}
     * @returns {Array<string>} sorted image names
     */
    getSortedImageNames = (rgb_urls) => {
        // The first sort will group images by the source image name
        const image_names = Object.keys(rgb_urls).sort();     
        let source_image_names = {}; // {source_image_name: [tile_image_name, ...]}
        for (let image_name of image_names) {
            // The image name can contain multiple `_` characters or none at all
            let source_image_name, split;
            if (image_name.includes("_")) {
                split = image_name.split("_");
                source_image_name = split.slice(0, split.length - 1).join("_");
            } else {
                source_image_name = image_name;
            }
            
            if (source_image_name in source_image_names) {
                // Add the image name to the list
                source_image_names[source_image_name].push(image_name);
            } else {
                // If the source image name is not in the list of source image names,
                // then this is the first tile of the source image
                source_image_names[image_name] = [];
            }
        }

        // The second sort will sort the tiles by tile number
        // First, add the source image names (tile number 0)
        let sorted_image_names = Object.keys(source_image_names);
        // Then, add the tile images in groups by tile number
        const n_tiles_per_image = source_image_names[sorted_image_names[0]].length;
        for (let i = 0; i < n_tiles_per_image; i++) {
            // Add the i-th tile of each source image
            for (let source_image_name in source_image_names) {
                sorted_image_names.push(source_image_names[source_image_name][i]);
            }
        }
        return sorted_image_names;
    }

    /**
     * Get the number of images that have any status other than NOT_USED
     * 
     * @param {object} image_statuses { image_name: image_status, ...} 
     * @returns {number} number of selected images
     */
    getNumberOfSelectedImages = (image_statuses) => {
        let n_selected = 0;
        for (let image_name in image_statuses) {
            if (image_statuses[image_name] !== IMAGE_STATUSES.NOT_USED) {
                n_selected++;
            }
        }
        // If no images are included, return the smaller of the number of images or the default number of tiles
        return n_selected > 0 ? n_selected : Math.min(Object.keys(image_statuses).length, DEFAULT_N_TILES);
    }

    /**
     * Cycle through the image layers for an image
     * 
     * @param {string} hover_image_id id of the current image
     * @param {number} new_image_layer_idx index of the new image layer 
     */
    changeImage = (hover_image_id, new_image_layer_idx = null) => {
        if (hover_image_id === null) {
            return;
        }
        // firstChild = image holder div
        // childNodes of image holder div = image layers

        let layers = document.getElementById(hover_image_id).firstChild.childNodes;
        // layers[0] is the image, layers[n] is image_stack[n-1], layers[layers.length-2] is the annotation-overlay, layers[layers.length-1] is the class-overlay
        for (let idx = 0; idx < layers.length; idx++) {
            let layer = layers[idx];
            // Skip overlays and hidden images
            if (layer.id.includes("overlay") || layer.classList.contains("hidden")) {
                continue;
            }

            // Change currently shown image to hidden
            layer.classList.add("hidden");

            if (new_image_layer_idx === null) {
                if (idx + 1 === layers.length - 2) {
                    // Last two indices are the annotation-overlay and the class-overlay
                    // If we're at the last layer, turn on the og image
                    new_image_layer_idx = 0;
                } else {
                    // Un-hide next image
                    new_image_layer_idx = idx + 1;
                }
            }
            break;
        }

        // Show the new_image_layer_idx
        layers[new_image_layer_idx].classList.remove("hidden");
    }

    /**
     * Change all images such that they show the same layer
     */
    changeAllImages = () => {
        // Layer to show is the next layer after component.current_image_layer_idx
        // if the next index is the last index, show the original image
        // image_stack doesn't include the original image, so if there are n layers,
        // so when the current layer idx is n, the new layer idx should return to 0
        let new_image_layer_idx = this.current_image_layer_idx + 1;
        if (this.current_image_layer_idx === this.state.image_stack.length) {
            new_image_layer_idx = 0;
        }
    
        // Change the image for all images
        for (let image_name of this.state.image_names) {
            this.changeImage(image_name, new_image_layer_idx);
        }
        // Update the current image layer index
        this.current_image_layer_idx = new_image_layer_idx;
    }

    /**
     * Toggle Annotation overlay for the hovered image
     * 
     * @param {string} hover_image_id id of the current image
     */
    toggleAnnotationOverlay = (hover_image_id = null, show_overlay = null) => {
        if (hover_image_id !== null) {
            let annotation_overlay = document.getElementById(hover_image_id + "-annotation-overlay");
            if (show_overlay === true || annotation_overlay.classList.contains("hidden")) {
                annotation_overlay.classList.remove("hidden");
            } else {
                annotation_overlay.classList.add("hidden");
            }
        }
    }

    /**
     * Toggle annotation overlays for all images
     */
    toggleAllAnnotationOverlays = () => {
        this.annotation_overlays_visible = !this.annotation_overlays_visible;
        for (let image_name of this.state.image_names) {
            this.toggleAnnotationOverlay(image_name, this.annotation_overlays_visible);
        }
    }

    /**
     * Scroll page to the next row 
     * 
     * @param {string} hover_image_id id of the current row
     * @param {string} keybind_name keybind name
     */
    autoScroll = (hover_image_id, keybind_name) => {
        let row_not_found = true;
        let scroll_at_max = false;
        if (hover_image_id !== null) {
            let jquery_next_image = $("#" + hover_image_id); // Start at current row
            let current_top = jquery_next_image.offset().top; // Top of current row
            let next_image;
            while (row_not_found) {
                // Try the next (or previous) image
                jquery_next_image = keybind_name === GRID_KEYBIND_NAMES.NEXT_ROW ? jquery_next_image.next() : jquery_next_image.prev();
                next_image = document.getElementById(jquery_next_image.attr("id"))
                
                // If the next_image has a different "top" as the current image, it's in a different row
                // This protects against scrolling to an image in the same row
                try {
                    if (jquery_next_image.offset().top !== current_top) {
                        // Ensure that the next image isn't hidden
                        if (!next_image.classList.contains("hidden")) {
                            // We found a row to scroll to
                            row_not_found = false;
                            break;
                        }
                    }
                } catch (e) {
                    row_not_found = false;
                    scroll_at_max = true;
                }
            }

            if (!scroll_at_max) {
                // Scroll to next row
                $(document).scrollTop(jquery_next_image.offset().top);
                // Set next image as hovered for consecutive navigation
                this.hover_image_id = jquery_next_image.attr("id");
                // Override scroll protection
                this.allow_next_scroll = true; 
            }
        }
    }

    /**
     * Run through the classes list and
     * check for supported overlays
     */
    initOverlays = () => {
        // Grab the document's head tag and create a style tag
        let document_head = document.getElementsByTagName("head")[0];
        let style = document.createElement("style");

        // Loop through all classes and append each class' overlay opacity to it
        for (let each_class of this.classes) {
            if (each_class.svg_overlay == null) {
                // If the overlay doesn't exist don't show it
                style.textContent += `div.${each_class.class_name} > * > img.class-overlay {
                    filter: opacity(0)
                }
                `;
            } else {
                // Use the default opacity
                style.textContent += `div.${each_class.class_name} > * > img.class-overlay {
                    filter: opacity(1)
                }
                `;
            }
        }

        // Append the newly created style tag to the documet head
        document_head.appendChild(style);
    }

    /**
     * Get an array of image layers for an image
     * 
     * @param {*} component component that called this function: pass in `this`
     * @param {string} image_name image name
     * @returns {Array} image stack; array of images
     */
    getImageStackByName = (image_name) => {
        let image_stack = [];
        for (let image_layer of this.state.image_stack) {
            if (image_name in image_layer) {
                image_stack.push(image_layer[image_name]);
            }
        }
        return image_stack;
    }

    /**
     * Get the class name from the image name
     * 
     * @param {string} image_name
     * @returns {string} class name
     */
    getClassFromImageName = (image_name) => {
        // Default to the first class
        let _class = this.classes[0].class_name;
        // We only care if the image is excluded or not
        if ( this.state.labels && image_name in this.state.labels) {
            _class = this.state.labels[image_name]["class"];
        }
        return _class
    }

    /**
     * Initialize labels
     * 
     * @param {Array<string>} image_names list of image names
     * @param {object} image_statuses { image_name: image_status, ...}
     * @returns {object} labels
     */
    getInitLabels = (image_names, image_statuses) => {
        let labels = {};
        for (let image_name of image_names) {
            labels[image_name] = {
                "class": image_statuses[image_name] 
            }
        }
        return labels;
    }

    /**
     * Scrape the page for all the current labels
     * 
     * @param {boolean} set_state whether to update the state with the new labels
     * @returns {object} labels
     */
    updateLocalLabels = (set_state = true) => {
        // Get state of each GridImage
        let labels = {};
        for (let i=0; i < this.state.image_names.length; i++) {
            let image_name = this.state.image_names[i];
            let class_name = document.getElementById(image_name).classList[1];
            labels[image_name] = {
                "class": class_name === IMAGE_STATUSES.EXCLUDED ? IMAGE_STATUSES.EXCLUDED : "normal"
            }
        }
        if (set_state) {
            // Update the state with the new labels
            this.setState({
                images: this.state.images,
                image_names: this.state.image_names,
                labels: labels,
            });
        } 
        return labels;
    }

    /**
     * Clear all changes
     */
    clearAllChanges = () => {
        this.setState({
            labels: JSON.parse(JSON.stringify(this.og_labels)),
        });
        // Force a re-render
        // componentDidUpdate will handle resetting this.n_selected_images
        this.component_updater++;
    }

    /**
     * Save labels and update image statuses if necessary
     * 
     */
    saveLabels = async (alert_user = false) => {
        // Get the labels
        let labels = this.updateLocalLabels(false);
        let did_update = false;
        let all_promises = [];
        // Submit any labels that are different from the original labels
        for (let i = 0; i < this.state.image_names.length; i++) {
            let image_name = this.state.image_names[i];
            if (i < this.n_selected_images) {
                // Logic for selected images
                if (labels[image_name]["class"] !== this.og_labels[image_name]["class"]) {
                    // If the class is different, update the image status
                    if (labels[image_name]["class"] === IMAGE_STATUSES.EXCLUDED) {
                        // Exclude the image
                        all_promises.push(setImageStatus(this.state.image_ids[image_name], IMAGE_STATUSES.EXCLUDED));
                    } else if (
                        this.og_labels[image_name]["class"] === IMAGE_STATUSES.NOT_USED ||
                        this.og_labels[image_name]["class"] === IMAGE_STATUSES.EXCLUDED
                    ) {
                        // Include the image (handles both NOT_USED, EXCLUDED -> READY_TO_ANNOTATE)
                        all_promises.push(includeImage(this.state.image_ids[image_name]));
                    }
                    did_update = true;
                }
            } else {
                // Logic for hidden images
                // If the image is hidden, set the image status to EXCLUDED unless it is currently NOT_USED
                if (this.og_labels[image_name]["class"] !== IMAGE_STATUSES.NOT_USED) {
                    all_promises.push(setImageStatus(this.state.image_ids[image_name], IMAGE_STATUSES.EXCLUDED));
                    did_update = true;
                }
            }
        }
        if (did_update && alert_user) {
            // Save labels as the new og_labels
            this.og_labels = JSON.parse(JSON.stringify(labels));
            this.showToast("Image statuses updated.");
        }
        // Wait for all promises to resolve
        return Promise.all(all_promises);
    }
    

    /**
     * Handle keypresses for the grid width input
     * 
     * @param {*} event on change event
     */
    changeGridWidth = (event) => {
        this.grid_width = event.target.value;

        // Reformat the grid by changing the grid-table css
        document.getElementById("grid-table").style.gridTemplateColumns = "repeat(" + this.grid_width + ", 1fr)";

        // Update overlays
        update_all_overlays(this);
    }

    /**
     * Handle button clicks to change the image size
     * 
     * @param {number} scale multiplier for the image size
     */
    changeImageSize = (scale) => {
        // Max width is 100% of the grid container
        this.grid_width_percent = Math.min(100, this.grid_width_percent * scale);
        // Change the image size
        document.getElementsByClassName("grid-container")[0].style.width = this.grid_width_percent + "%";
    }
    
    /**
     * Handle the change in the number of images to process
     * 
     * @param {number} n_images number of images to process
     */
    changeSelectedImages = (n_images) => {
        this.n_selected_images = n_images;
        // Loop through all image names, showing the first n images and hiding the rest
        for (let i = 0; i < this.state.image_names.length; i++) {
            let image_name = this.state.image_names[i];
            let was_previously_excluded = this.og_labels[image_name]["class"] === IMAGE_STATUSES.EXCLUDED;
            let is_complete = this.state.labels[image_name]["class"] === IMAGE_STATUSES.COMPLETE;
            let is_new_tile = this.og_labels[image_name]["class"] === IMAGE_STATUSES.NOT_USED;
            let is_selected = i < n_images;
            let image = document.getElementById(image_name);
            
            if (this.show_new_tiles_only) {
                // Only show new tiles that are currently selected
                image.classList.toggle("hidden", !(is_new_tile && is_selected));
            } else {
                if (
                    !is_selected ||
                    (
                        (!this.show_previously_excluded && was_previously_excluded) ||
                        (!this.show_completed && is_complete)
                    )
                ) {
                    // Hide unselected images and those filtered out by !show_previously_excluded or !show_completed
                    image.classList.add("hidden");
                } else {
                    // Show selected images that are not filtered out
                    image.classList.remove("hidden");
                }
            }
        }

        // Set the number of labeling images
        this.setNumberOfLabelingImages();
    }

    /**
     * Handle the weed pressure form submission
     * 
     * @param {object} e form submission event
     */
    handleWeedPressureForm = async (e) => {
        e.preventDefault();
        if (e.target[0].checked) {
            // The high pressure option is selected
            this.saveLabels();
            setWeedPressureHigh(this.analytic_id);
            this.showToast("High Pressure - Blanket Rx submitted!");
            // Return to analytics page
            this.navigateTo(COMPONENTS.ANALYTICS);
        } else if (e.target[1].checked) {
            // The low pressure option is selected
            this.showToast("Low Pressure - Point Label submitted! Loading Job...");
            await this.saveLabels();
            setWeedPressureLow(this.analytic_id);
            // Go to Point Labeling
            this.navigateTo(
                COMPONENTS.JOBS,
                {
                    analytic_id: this.analytic_id,
                    field_name: this.field_name,
                    org_name: this.org_name,
                    job_type: JOB_TYPES.POINT_LABELING
                }
            );
        }
    }

    /**
     * Handle completion of the pre polygon review
     * 
     */
    handlePrePolygonReviewComplete = async () => {
        this.showToast("Pre Polygon Review marked as complete! Loading Job...");
        await this.saveLabels();
        markPrePolygonReviewComplete(this.analytic_id);
        // Go to Polygon Labeling
        this.navigateTo(
            COMPONENTS.JOBS,
            {
                analytic_id: this.analytic_id,
                field_name: this.field_name,
                org_name: this.org_name,
                job_type: JOB_TYPES.POLYGON_LABELING
            }
        );
    }

    /**
     * Handle completion of the pre polygon review
     * 
     */
    handleManageImagesSubmit = async () => {
        this.showToast("Changes submitted! Returning to Job...");
        await this.saveLabels();
        // Go to Job
        this.navigateTo(
            COMPONENTS.JOBS,
            {
                analytic_id: this.analytic_id,
                field_name: this.field_name,
                org_name: this.org_name,
                job_type: this.job[JOB_KEYS.JOB_TYPE]
            }
        );
    }


    render() {
        return (
            <div className="Grid" key={this.component_updater}>
                <div className="header-container">
                    <h2>Analytic ID: {this.analytic_id}</h2>
                    <h3>{this.org_name}: {this.field_name}</h3>
                    <div className="controls-container">
                        <div className="width-and-size-container">
                            <div className="change-grid-width-container">
                                <label htmlFor="change-n-images">
                                    Number of Images to Process:
                                </label>
                                <input 
                                    id="change-n-images"
                                    type="number"
                                    defaultValue={0} 
                                    size={3} // Number of visible digits
                                    step={1} 
                                    min={1}
                                    max={this.state.image_names.length}
                                    onChange={(event) => this.changeSelectedImages(event.target.value)}>
                                </input>
                                <p style={{marginLeft: "5px"}}>/ {this.state.image_names.length}</p>
                            </div>
                        </div>
                        <div className="width-and-size-container">
                            <div className="change-grid-width-container">
                                <label htmlFor="change-grid-width-og">
                                    Grid Width:
                                </label>
                                <input 
                                    id="change-grid-width-og"
                                    type="number" 
                                    defaultValue={this.grid_width} 
                                    size={2} // Number of visible digits
                                    step={1} 
                                    min={1}
                                    max={99}
                                    onChange={(event) => this.changeGridWidth(event)}>
                                </input>
                            </div>
                            <div className="change-image-size-container">
                                <p>Image Size:</p>
                                <button
                                    className="change-image-size button"
                                    onClick={() => this.changeImageSize(1.1)}>
                                    +
                                </button>
                                <button
                                    className="change-image-size button"
                                    onClick={() => this.changeImageSize(1/1.1)}>
                                    -
                                </button>
                            </div>
                        </div>
                        <div className = "n-images-container">
                            <p id="n-labeling-images"></p>
                        </div>
                        {/* Weed Pressure Decision Box */}
                        {this.grid_mode === GRID_MODES.WEED_PRESSURE_DECISION &&
                            <div className="weed-pressure-container">
                                {/*    
                                    Radio buttons in a submit form
                                    Two options: High and Low Weed Pressure
                                */}
                                <form
                                    className="weed-pressure-form"
                                    aria-label="Weed Pressure Form" 
                                    onSubmit={(e) => this.handleWeedPressureForm(e)}>
                                    <label className="weed-pressure-radio">
                                        <input type="radio" value="high" name="weed-pressure" />
                                        High Pressure - Blanket Rx
                                    </label>
                                    <label className="weed-pressure-radio">
                                        <input type="radio" value="low" name="weed-pressure" />
                                        Low Pressure - Point Label
                                    </label>
                                    <div>
                                        <button 
                                            className="weed-pressure-submit button"
                                            type="submit">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        }
                        {/* Pre Polygon Review Button */}
                        {this.grid_mode === GRID_MODES.PRE_POLYGON_REVIEW &&
                            <button 
                                onClick={() => this.handlePrePolygonReviewComplete()} 
                                className="button"
                                title="Mark Pre Polygon Review as Complete"
                            >
                                Complete Pre Polygon Review
                            </button>
                        }
                        {/* Manage Images Button */}
                        {this.grid_mode === GRID_MODES.MANAGE_IMAGES &&
                            <button 
                                onClick={() => this.handleManageImagesSubmit()} 
                                className="button"
                                title="Submit Changes to Image Statuses"
                            >
                                Submit Changes
                            </button>
                        }
                        {/* Clear All Labels Button */}
                        <button 
                            onClick={() => this.clearAllChanges()} 
                            className="button"
                            title="Clear all Changes"
                        >
                            Clear All Changes
                        </button>
                    </div>
                    {/* Refresh button */}
                    <div className="refresh-container">
                        <button 
                            className="refresh-button"
                            onClick={this.updateGridWrapper}
                        >
                            <CachedIcon id={this.grid_refresh_icon_id}/>
                        </button>
                    
                        {/* Checkboxes to filter by image status when managing images */}
                        { this.grid_mode === GRID_MODES.MANAGE_IMAGES &&
                            <div className="filter-container">
                                {/* Show Previously Excluded checkbox */}
                                <div className="checkbox-container show-previously-excluded">
                                    <label htmlFor="show-previously-excluded">Show Previously Excluded</label>
                                    <input
                                        type="checkbox"
                                        id="show-previously-excluded"
                                        name="show-previously-excluded"
                                        defaultChecked={this.show_previously_excluded}
                                        onChange={(event) => {
                                            this.show_previously_excluded = event.target.checked;
                                            this.changeSelectedImages(this.n_selected_images);
                                        }}
                                    />
                                </div>
                                {/* Show Completed checkbox */}
                                <div className="checkbox-container show-completed">
                                    <label htmlFor="show-completed">Show Completed</label>
                                    <input
                                        type="checkbox"
                                        id="show-completed"
                                        name="show-completed"
                                        defaultChecked={this.show_completed}
                                        onChange={(event) => {
                                            this.show_completed = event.target.checked;
                                            this.changeSelectedImages(this.n_selected_images);
                                        }}
                                    />
                                </div>
                                {/* Show New Tiles Only checkbox */}
                                <div className="checkbox-container show-new-tiles-only">
                                    <label htmlFor="show-new-tiles-only">Show New Images Only</label>
                                    <input
                                        type="checkbox"
                                        id="show-new-tiles-only"
                                        name="show-new-tiles-only"
                                        defaultChecked={this.show_new_tiles_only}
                                        onChange={(event) => {
                                            this.show_new_tiles_only = event.target.checked;
                                            this.changeSelectedImages(this.n_selected_images);
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div 
                    className="grid-container" 
                    style={{
                        width: this.grid_width_percent + "%",
                    }}
                >
                    <div 
                        id="grid-table" 
                        className="grid-table"
                        style={{
                            display:"grid", 
                            gridTemplateColumns: "repeat(" + this.grid_width + ", 1fr)",
                        }}
                    >
                        {this.state.image_names.map(image_name => (
                            // Use Fragment so React doesn't complain about not having a key,
                            // but we don't want to add a div to the DOM
                            <Fragment key={image_name}>
                                <GridImage
                                    image={this.state.images[image_name]} 
                                    image_stack={this.getImageStackByName(image_name)}
                                    annotation_overlay={this.state.annotation_overlay_urls[image_name]}
                                    image_name={image_name}
                                    classes={this.classes}
                                    default_class={this.getClassFromImageName(image_name)}
                                    toast_fn={this.showToast}
                                    update_callback={this.setNumberOfLabelingImages}
                                />
                            </Fragment>
                        ))}
                    </div>
                </div>
            </div>       
        )
    }
}

export default WeedPressure;
import "../css/Analytics.css";
import { 
    ANALYTIC_DISPLAY_KEYS,
    ANALYTIC_DISPLAY_STATUSES,
    ANALYTIC_KEYS, 
    ANALYTIC_STATUSES, 
    COMPONENTS,
    GRID_MODES,
} from "../utils/constants";
import { activeAdminUrl, stepFunctionsUrl } from "../utils/urls";
import { makeDisplayString, toggle_refresh_icon_by_id, unmakeDisplayString } from "../utils/display";
import CachedIcon from "@mui/icons-material/Cached";
import { DataGrid } from "@mui/x-data-grid";
import PixelPatchComponent from "./PixelPatchComponent";
import { copyToClipboard } from "../utils/clipboard";
import { getAnalytics } from "../api/analytics";
import nav_link_icon from "../assets/nav_link_icon.png";

class Analytics extends PixelPatchComponent {
    state = {
        analytics: [],
        columns: [],
        filterModelItems: [], // https://mui.com/x/react-data-grid/filtering/
    };
    columnVisibilityModel = {};
    refresh_icon_id = "analytics-refresh-icon";

    constructor(props) {
        super(props);

        this.show_fulfilled = false;
        this.show_abandoned = false;
        this.show_only_labeling_jobs = false;

        // Bind functions
        this.getLatestAnalyticsWrapper = this.getLatestAnalyticsWrapper.bind(this);
        this.getLatestAnalytics = this.getLatestAnalytics.bind(this);
        this.getFilterItems = this.getFilterItems.bind(this);
        this.handleCellClick = this.handleCellClick.bind(this);
    }

    /**
     * Fetch analytics from the API and set the state with the analytics and columns
     */
    componentDidMount = async () => {
        await this.getLatestAnalyticsWrapper();
    }

    /**
     * Wrap the getLatestAnalytics function to render the refresh icon
     */
    getLatestAnalyticsWrapper = async () => {
        toggle_refresh_icon_by_id(this.refresh_icon_id, true);
        await this.getLatestAnalytics();
        toggle_refresh_icon_by_id(this.refresh_icon_id, false);
    }

    /**
     * Get the latest analytics from the API and set the state with the analytics
     */
    getLatestAnalytics = async () =>{
        const analytics = await getAnalytics();

        // Sort the analytics by priority in descending order, with secondary sorting by ID in descending order
        analytics.sort((a, b) => {
            if (a[ANALYTIC_KEYS.ANALYTIC_PRIORITY] === b[ANALYTIC_KEYS.ANALYTIC_PRIORITY]) {
                return b[ANALYTIC_KEYS.ID] - a[ANALYTIC_KEYS.ID];
            }
            return b[ANALYTIC_KEYS.ANALYTIC_PRIORITY] - a[ANALYTIC_KEYS.ANALYTIC_PRIORITY];
        });

        const columns = Object.keys(analytics[0]).map((key) => {
            const column = {
                field: key,
                headerName: makeDisplayString(key),
                flex: 1,
                cellClassName: (params) => {
                    // Color just the cell based on the status
                    if (Object.values(ANALYTIC_STATUSES).includes(params.value)) {
                        return params.value;
                    } 
                    // Color the whole row if fulfilled or abandoned
                    else if (params.row[ANALYTIC_KEYS.ANALYTIC_STATUS] === ANALYTIC_STATUSES.FULFILLED) {
                        return ANALYTIC_STATUSES.FULFILLED;
                    } else if (params.row[ANALYTIC_KEYS.ANALYTIC_STATUS] === ANALYTIC_STATUSES.ABANDONED) {
                        return ANALYTIC_STATUSES.ABANDONED;
                    } else {
                        return "copy-pointer"
                    }
                },
            };

            // https://mui.com/x/react-data-grid/column-definition/#rendering-cells
            // For the analytic status column, show that the cell is clickable
            switch (key) {
                case ANALYTIC_KEYS.ANALYTIC_STATUS:
                    column.renderCell = (params) => (
                        <div className="cursor-pointer">
                            {makeDisplayString(params.value)}
                        </div>
                    );
                    break;
                case ANALYTIC_KEYS.STEP_FUNCTION_EXECUTION_ID:
                    // Construct a link to the step function
                    column.renderCell = (params) => (
                        <div className="cursor-pointer">
                            <a href={stepFunctionsUrl(params.value)}>
                                {params.value}
                            </a>
                        </div>
                    );
                    break;
                case ANALYTIC_KEYS.SENTERA_ID:
                    // Construct a link to the active admin
                    column.renderCell = (params) => (
                        <div className="cursor-pointer">
                            <a href={activeAdminUrl(params.value)}>
                                {params.value}
                            </a>
                        </div>
                    );
                    break;
                case ANALYTIC_KEYS.ALLOW_EXTERNAL_ANNOTATION:
                case ANALYTIC_KEYS.DO_FINE_TUNING:
                    // Yes/No instead of true/false
                    column.renderCell = (params) => (
                        <div className="cursor-pointer">
                            {params.value ? "Yes" : "No"}
                        </div>
                    );
                    break;
                default:
                    break;
            }

            // Set the column visibility model
            this.columnVisibilityModel[key] = ANALYTIC_DISPLAY_KEYS.includes(key);

            return column;
        });
        // Add additional columns: details
        const additional_column_names = ["details"];
        for (const column_name of additional_column_names) {
            columns.push({
                field: column_name,
                headerName: makeDisplayString(column_name),
                flex: 0.5,
                renderCell: () => (
                    <div className="cursor-pointer">
                        <img
                            className="nav-link-icon"
                            src={nav_link_icon}
                            alt="Details"
                        />
                    </div>
                ),
            });
        }
        // Add `details` to each analytic
        for (let i = 0; i < analytics.length; i++) {
            analytics[i]["details"] = "details";
        }
        this.setState({ 
            analytics: analytics, 
            columns: columns, 
            filterModelItems: this.getFilterItems(),
        });
    }

    /**
     * Get the list of filter items for the DataGrid
     * 
     */
    getFilterItems = () => {
        if (this.show_only_labeling_jobs) {
            return [{
                field: ANALYTIC_KEYS.ANALYTIC_STATUS,
                operator: "isAnyOf",
                value: [ANALYTIC_STATUSES.POINT_LABELING, ANALYTIC_STATUSES.POLYGON_LABELING],
            }];
        }

        let display_statuses = ANALYTIC_DISPLAY_STATUSES;
        if (this.show_fulfilled) {
            display_statuses = display_statuses.concat(ANALYTIC_STATUSES.FULFILLED);
        }
        if (this.show_abandoned) {
            display_statuses = display_statuses.concat(ANALYTIC_STATUSES.ABANDONED);
        }
        return [{
            field: ANALYTIC_KEYS.ANALYTIC_STATUS,
            operator: "isAnyOf",
            value: display_statuses,
        }];;
    }

    /**
     * Handle cell click events
     * 
     * @param {*} params Details about the cell and row that was clicked
     * @param {*} event 
     * @param {*} details 
     */
    handleCellClick = async (params, event, details) => {
        const props = {
            analytic_id: params.row[ANALYTIC_KEYS.ID], 
            field_name: params.row[ANALYTIC_KEYS.FIELD_NAME],
            org_name: params.row[ANALYTIC_KEYS.ORGANIZATION_NAME],
        };
        switch (unmakeDisplayString(params.formattedValue)) {
            case ANALYTIC_STATUSES.WEED_PRESSURE_DECISION:
                this.navigateTo(
                    COMPONENTS.GRID, 
                    { 
                        ...props,
                        grid_mode: GRID_MODES.WEED_PRESSURE_DECISION
                    }
                );
                break;
            case ANALYTIC_STATUSES.POINT_LABELING:
            case ANALYTIC_STATUSES.POLYGON_LABELING:
                this.navigateTo(
                    COMPONENTS.JOBS,
                    { 
                        ...props,
                        job_type: params.formattedValue,
                    }
                );
                break;
            case ANALYTIC_STATUSES.PRE_POLYGON_REVIEW:
                this.navigateTo(
                    COMPONENTS.GRID, 
                    { 
                        ...props,
                        grid_mode: GRID_MODES.PRE_POLYGON_REVIEW
                    }
                );
                break;
            case "details":
                this.navigateTo(
                    COMPONENTS.ANALYTIC_DETAILS,
                    props
                );
                break;
            default:
                let value = params.value;
                // Copy the value to the clipboard
                copyToClipboard(value);
                this.showToast(
                    "Copied " + value + " to clipboard.",
                    2000,
                );
                break;
        }
    }

    render() {
        return (
            <div className="Analytics">
                <h2>Analytics</h2>
                <div className="controls-container">
                    {/* Refresh button */}
                    <div className="refresh-container">
                        <button 
                            className="refresh-button"
                            onClick={this.getLatestAnalyticsWrapper}
                        >
                            <CachedIcon id={this.refresh_icon_id}/>
                        </button>
                    </div>
                    <div className="filter-container">
                        {/* Show only labeling jobs checkbox */}
                        <div className="checkbox-container show-only-labeling-jobs">
                            <label htmlFor="show-only-labeling-jobs">Show Only Labeling Jobs</label>
                            <input
                                type="checkbox"
                                id="show-only-labeling-jobs"
                                name="show-only-labeling-jobs"
                                onChange={(event) => {
                                    this.show_only_labeling_jobs = event.target.checked;
                                    this.setState({ filterModelItems: this.getFilterItems() });
                                }}
                            />
                        </div>
                        {/* Show fulfilled checkbox */}
                        <div className="checkbox-container show-fulfilled">
                            <label htmlFor="show-fulfilled">Show Fulfilled</label>
                            <input 
                                type="checkbox" 
                                id="show-fulfilled" 
                                name="show-fulfilled" 
                                onChange={(event) => {
                                    this.show_fulfilled = event.target.checked;
                                    this.setState({ filterModelItems: this.getFilterItems() });
                                }}
                            />
                        </div>
                        {/* Show abandoned checkbox */}
                        <div className="checkbox-container show-abandoned">
                            <label htmlFor="show-abandoned">Show Abandoned</label>
                            <input 
                                type="checkbox" 
                                id="show-abandoned" 
                                name="show-abandoned" 
                                onChange={(event) => {
                                    this.show_abandoned = event.target.checked;
                                    this.setState({ filterModelItems: this.getFilterItems() });
                                }}
                            />
                        </div>
                    </div>
                </div>
                {/* https://mui.com/x/api/data-grid/data-grid/ */}
                <DataGrid
                    rows={this.state.analytics} 
                    columns={this.state.columns} 
                    onCellClick={this.handleCellClick}
                    initialState={{
                        columns: {
                            columnVisibilityModel: this.columnVisibilityModel,
                        },
                        pagination: {
                            paginationModel: { pageSize: 10 }
                        },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                    filterModel={{
                        items: this.state.filterModelItems,
                    }}
                    // On filter change, update the filter model items
                    onFilterModelChange={(model) => {
                        this.setState({ filterModelItems: model.items });
                    }}
                >
                </DataGrid>
            </div>
        );
    }
}

export default Analytics;
// API for interacting with Images
import { 
    AUDIT_LOGS_ENDPOINT,
    IMAGES_ENDPOINT, 
    IMAGE_STATUSES,
    SUBIMAGE_SUBTASK_KEY, 
} from "../utils/constants";
import { api_consolidator_error_handler } from "../utils/api_utils";
import { getMostRecentAnnotatorFromAuditLogs } from "../utils/audit_logs";
/**
 * Get image by ID
 * 
 * @param {number} image_id Image ID
 * @returns {Promise<Object>} Image object
 */
export async function getImageByID(image_id) {
    return await api_consolidator_error_handler("GET", `${IMAGES_ENDPOINT}/${image_id}`);
}

/**
 * Lock an image
 * 
 * @param {number} image_id Image ID
 * @returns {Promise<boolean>} True on success
 */
export async function lockImage(image_id, is_review = false) {
    if (is_review) {
        return await api_consolidator_error_handler("POST", `${IMAGES_ENDPOINT}/${image_id}/review`);
    } else {
        return await api_consolidator_error_handler("POST", `${IMAGES_ENDPOINT}/${image_id}/annotate`);
    }
}

/**
 * Unlock an image
 * 
 * @param {number} image_id Image ID
 * @returns {Promise<boolean>} True on success
 */
export async function unlockImage(image_id) {
    return await api_consolidator_error_handler("POST", `${IMAGES_ENDPOINT}/${image_id}/unlock`);
}

/**
 * Set the status of an image
 * 
 * @param {number} image_id Image ID
 * @param {string} status Status
 * @returns {Promise<Object>} Success or error message
 */
export async function setImageStatus(image_id, status) {
    switch (status) {
        case IMAGE_STATUSES.READY_TO_REVIEW:
            return await api_consolidator_error_handler("POST", `${IMAGES_ENDPOINT}/${image_id}/finish_annotating`);
        case IMAGE_STATUSES.COMPLETE:
            return await api_consolidator_error_handler("POST", `${IMAGES_ENDPOINT}/${image_id}/review_passed`);
        case IMAGE_STATUSES.READY_TO_ANNOTATE:
            return await api_consolidator_error_handler("POST", `${IMAGES_ENDPOINT}/${image_id}/review_failed`);
        case IMAGE_STATUSES.EXCLUDED:
            return await api_consolidator_error_handler("POST", `${IMAGES_ENDPOINT}/${image_id}/exclude`);
        default:
            console.error("Image status transition not implemented: " + status);
            return null;
    }
}

/**
 * Unmark an image as excluded (set it to IMAGE_STATUSES.READY_TO_ANNOTATE)
 * 
 * @param {number} image_id Image ID
 */
export async function includeImage(image_id) {
    return await api_consolidator_error_handler("POST", `${IMAGES_ENDPOINT}/${image_id}/include`);
}

/**
 * Save annotations for an image
 * 
 * @param {number} image_id Image ID
 * @param {Object} annotations Annotations
 * @param {number} weed_annotation_count Number of weed annotations
 * @param {number} crop_annotation_count Number of crop annotations
 * @returns {Promise<Object>} Success or error message
 */
export async function saveAnnotations(image_id, annotations, weed_annotation_count = 0, crop_annotation_count = 0) {
    // Filter out the subimage subtask, as it is only for internal use
    if (SUBIMAGE_SUBTASK_KEY in annotations.annotations) {
        delete annotations.annotations[SUBIMAGE_SUBTASK_KEY];
    }
    return await api_consolidator_error_handler(
        "PATCH", 
        `${IMAGES_ENDPOINT}/${image_id}/annotations`, 
        null,
        {weed_annotation_count: weed_annotation_count, crop_annotation_count: crop_annotation_count}, 
        annotations
    );
}

/**
 * Get image audit logs
 * 
 * @param {number} image_id Image ID
 * @returns {Promise<Array>} List of audit logs
 */
export async function getAuditLogsByImageID(image_id) {
    return await api_consolidator_error_handler("GET", `${IMAGES_ENDPOINT}/${image_id}/${AUDIT_LOGS_ENDPOINT}`);
}

/**
 * Get most recent annotator for an image from the audit logs
 * 
 * @param {number} image_id Image ID
 * @returns {Promise<string>} Annotator name
 */
export async function getMostRecentAnnotator(image_id) {
    const audit_logs = await getAuditLogsByImageID(image_id);
    return getMostRecentAnnotatorFromAuditLogs(audit_logs);
}

/**
 * Get the total number of images ready to annotate. Differs for internal/external users.
 * 
 * @param {string} job_type Job type
 * @returns {Promise<number>} Number of images
 */
export async function getNumberOfImagesReadyToAnnotate(job_type) {
    return await api_consolidator_error_handler("GET", `${IMAGES_ENDPOINT}/count`, null, {job_type: job_type});
}

/**
 * Get the next image to annotate across all analytics. Differs for internal/external users.
 * 
 * @param {string} job_type Job type
 * @returns {Promise<Object>} Image object
 */
export async function getNextImageToAnnotate(job_type) {
    return await api_consolidator_error_handler("GET", `${IMAGES_ENDPOINT}/next_annotate_image`, null, {job_type: job_type});
}
import "../css/AnalyticDetails.css";
import { 
    ANALYTIC_KEYS,
    ANALYTIC_STATUSES,
    AUDIT_LOG_DISPLAY_KEYS, 
    AUDIT_LOG_DISPLAY_NAMES, 
    AUDIT_LOG_KEYS, 
    COMPONENTS, 
    GRID_MODES,
    JOB_DETAILS_COLUMN_NAMES,
    JOB_KEYS,
    JOB_TYPES 
} from "../utils/constants";
import { 
    emailToDisplayName, 
    makeDisplayString, 
    toggle_refresh_icon_by_id,
    unmakeDisplayString 
} from "../utils/display";
import { 
    getAnalyticByID, 
    getAuditLogsByAnalyticID, 
    getJobsByAnalyticID, 
    markAnalyticAsAbandoned,
    patchAnalytic, 
} from "../api/analytics";
import CachedIcon from "@mui/icons-material/Cached";
import { DataGrid } from "@mui/x-data-grid";
import PixelPatchComponent from "./PixelPatchComponent";
import { getAuditLogDisplayName } from "../utils/audit_logs";
import nav_link_icon from "../assets/nav_link_icon.png";

class AnalyticDetails extends PixelPatchComponent {
    state = {
        analytic_id: -1,
        field_name: "",
        org_name: "",
        analytic_status: ANALYTIC_STATUSES.ABANDONED,
        point_labeling_job: {},
        polygon_labeling_job: {},
        job_details_rows: [],
        job_details_columns: [],
        audit_logs_rows: [],
        audit_logs_columns: [],
        weed_pressure_decision_is_completed: false,
        pre_polygon_review_is_completed: false,
    }
    auditLogsColumnVisibilityModel = {};
    analytic_details_refresh_icon_id = "analytic-details-refresh-icon";
    job_details_refresh_icon_id = "job-details-refresh-icon";
    audit_logs_refresh_icon_id = "audit-logs-refresh-icon";
    constructor(props) {
        super(props);
        
        // Get job and analytic ids
        this.state.analytic_id = props.analytic_id || "No Analytic ID";
        this.state.field_name = props.field_name || "No Field Name";
        this.state.org_name = props.org_name || "No Org Name";

        this.analytic = {};

        // Bind functions
        this.updateAnalyticDetailsWrapper = this.updateAnalyticDetailsWrapper.bind(this);
        this.updateAnalyticDetails = this.updateAnalyticDetails.bind(this);
        this.updateJobDetailsWrapper = this.updateJobDetailsWrapper.bind(this);
        this.updateJobDetails = this.updateJobDetails.bind(this);
        this.updateAuditLogsWrapper = this.updateAuditLogsWrapper.bind(this);
        this.updateAuditLogs = this.updateAuditLogs.bind(this);
        this.updatePatchAnalyticForm = this.updatePatchAnalyticForm.bind(this);
        this.handleCellClick = this.handleCellClick.bind(this);
    }

    // Get the job and audit log details for the analytic and construct the DataGrids
    async componentDidMount() {
        if (this.state.analytic_id === "No Analytic ID") {
            console.error("Invalid analytic id: ", this.state.analytic_id);
            return;
        }

        // Get the job details for the analytic, which also gets the audit logs
        await this.updateJobDetailsWrapper();
    }

    updateAnalyticDetailsWrapper = async () => {
        toggle_refresh_icon_by_id(this.analytic_details_refresh_icon_id, true);
        // Get the analytic details
        this.analytic = await getAnalyticByID(this.state.analytic_id);
        await this.updateAnalyticDetails();
        toggle_refresh_icon_by_id(this.analytic_details_refresh_icon_id, false);
    }

    // Update the analytic details in the DataGrid
    updateAnalyticDetails = async () => {
        this.updatePatchAnalyticForm();
        this.setState({ 
            analytic_status: this.analytic[ANALYTIC_KEYS.ANALYTIC_STATUS],
        });
    }

    /**
     * Wrap the updateJobDetails function to render the refresh icon
     */
    updateJobDetailsWrapper = async () => {
        toggle_refresh_icon_by_id(this.job_details_refresh_icon_id, true);
        await this.updateAuditLogs();
        await this.updateJobDetails();
        toggle_refresh_icon_by_id(this.job_details_refresh_icon_id, false);
    }

    // Update the job details in the DataGrid
    updateJobDetails = async () => {
        // Get the jobs details for point and polygon labeling
        const jobs = await getJobsByAnalyticID(this.state.analytic_id);
        const point_labeling_job = jobs.find((job) => job[JOB_KEYS.JOB_TYPE] === JOB_TYPES.POINT_LABELING) || { completed: false };
        const polygon_labeling_job = jobs.find((job) => job[JOB_KEYS.JOB_TYPE] === JOB_TYPES.POLYGON_LABELING) || { completed: false };
        // Get analytic for weed pressure decision
        this.analytic = await getAnalyticByID(this.state.analytic_id);

        // Initialize the columns
        const column_names = Object.values(JOB_DETAILS_COLUMN_NAMES);
        let columns = [];
        for (const column_name of column_names) {
            let column = { 
                field: column_name, 
                headerName: makeDisplayString(column_name), 
                flex: 1, 
                headerAlign: "center",
                align: "center",
            };

            // Special rendering for the "Complete" and "Launch" columns
            switch (column_name) {
                case JOB_DETAILS_COLUMN_NAMES.COMPLETE:
                    column.renderCell = (params) => (
                        <div>
                            {params.value ? "Yes" : "No"}
                        </div>
                    );
                    break;
                case JOB_DETAILS_COLUMN_NAMES.LAUNCH:
                    column.renderCell = () => (
                        <div className="cursor-pointer">
                            <img
                                className="nav-link-icon"
                                src={nav_link_icon}
                                alt="Details"
                            />
                        </div>
                    );
                    break;
                default:
                    break;
            }

            columns.push(column);
        }

        // Get the rows for the DataGrid
        const job_names = [GRID_MODES.WEED_PRESSURE_DECISION, JOB_TYPES.POINT_LABELING, GRID_MODES.PRE_POLYGON_REVIEW, JOB_TYPES.POLYGON_LABELING];
        let rows = [];
        for (const job_name of job_names) {
            let is_complete = false;
            switch (job_name) {
                case GRID_MODES.WEED_PRESSURE_DECISION:
                    // From audit logs
                    is_complete = this.state.weed_pressure_decision_is_completed;
                    break;
                case JOB_TYPES.POINT_LABELING:
                    is_complete = point_labeling_job.completed;
                    break;
                case GRID_MODES.PRE_POLYGON_REVIEW:
                    // From audit logs
                    is_complete = this.state.pre_polygon_review_is_completed;
                    break;
                case JOB_TYPES.POLYGON_LABELING:
                    is_complete = polygon_labeling_job.completed;
                    break;
                default:
                    console.error("Invalid job name: ", job_name);
                    break;
            }
            rows.push({
                // Required ID
                id: job_name,
                // Job
                [JOB_DETAILS_COLUMN_NAMES.JOB]: makeDisplayString(job_name),
                // Complete
                [JOB_DETAILS_COLUMN_NAMES.COMPLETE]: is_complete,
                // Launch
                [JOB_DETAILS_COLUMN_NAMES.LAUNCH]: JOB_DETAILS_COLUMN_NAMES.LAUNCH,
            });
        }

        this.updatePatchAnalyticForm();
        this.setState({ 
            job_details_rows: rows, 
            job_details_columns: columns,
            point_labeling_job: point_labeling_job, 
            polygon_labeling_job: polygon_labeling_job,
            analytic_status: this.analytic[ANALYTIC_KEYS.ANALYTIC_STATUS],
        });
    }

    /**
     * Wrap the updateAuditLogs function to render the refresh icon
     */
    updateAuditLogsWrapper = async () => {
        toggle_refresh_icon_by_id(this.audit_logs_refresh_icon_id, true);
        await this.updateAuditLogs();
        toggle_refresh_icon_by_id(this.audit_logs_refresh_icon_id, false);
    }

    // Update the audit logs in the DataGrid
    updateAuditLogs = async () => {
        // Get the audit logs for the analytic
        const audit_logs = await getAuditLogsByAnalyticID(this.state.analytic_id);
        
        // Modify the email to display name
        for (const audit_log of audit_logs) {
            if (audit_log[AUDIT_LOG_KEYS.CREATED_BY_EMAIL] !== null) {
                audit_log[AUDIT_LOG_KEYS.CREATED_BY_EMAIL] = emailToDisplayName(audit_log[AUDIT_LOG_KEYS.CREATED_BY_EMAIL]);
            }
            // Get the display name for the event
            audit_log[AUDIT_LOG_KEYS.EVENT_NAME] = getAuditLogDisplayName(audit_log);
        }

        // Initialize the columns
        const column_names = Object.values(AUDIT_LOG_KEYS);
        let columns = [];
        for (const column_name of column_names) {
            let column = { 
                field: column_name, 
                headerName: makeDisplayString(column_name), 
                flex: 1, 
                headerAlign: "center",
                align: "center",
            };
            columns.push(column);
            
            // Set the column visibility model
            this.auditLogsColumnVisibilityModel[column_name] = AUDIT_LOG_DISPLAY_KEYS.includes(column_name);
        }

        this.setState({ 
            audit_logs_rows: audit_logs, 
            audit_logs_columns: columns,
            // If any event name contains "Weed Pressure"
            weed_pressure_decision_is_completed: audit_logs.some((audit_log) => 
                audit_log[AUDIT_LOG_KEYS.EVENT_NAME] === AUDIT_LOG_DISPLAY_NAMES.WEED_PRESSURE_LOW ||
                audit_log[AUDIT_LOG_KEYS.EVENT_NAME] === AUDIT_LOG_DISPLAY_NAMES.WEED_PRESSURE_HIGH
            ),
            // If any event name contains "Pre-Polygon Review"
            pre_polygon_review_is_completed: audit_logs.some((audit_log) => 
                audit_log[AUDIT_LOG_KEYS.EVENT_NAME] === AUDIT_LOG_DISPLAY_NAMES.PRE_POLYGON_REVIEW_COMPLETED
            ), 
        });
    }

    /**
     * Update the patch analytic form
     *
     */
    updatePatchAnalyticForm = () => {
        if (this.analytic[ANALYTIC_KEYS.ANALYTIC_PRIORITY] !== undefined) {
            let priority = document.getElementById("priority");
            priority.value = this.analytic[ANALYTIC_KEYS.ANALYTIC_PRIORITY];
        }
        if (this.analytic[ANALYTIC_KEYS.ALLOW_EXTERNAL_ANNOTATION] !== undefined) {
            let allow_external_annotation = document.getElementById("allow-external-annotation");
            allow_external_annotation.checked = this.analytic[ANALYTIC_KEYS.ALLOW_EXTERNAL_ANNOTATION];
        }
    }

    /**
     * Handle cell click events
     * 
     * @param {*} params Details about the cell and row that was clicked
     * @param {*} event 
     * @param {*} details 
     */
    handleCellClick = async (params, event, details) => {
        const row_id = params.row.id;
        let job;
        switch (unmakeDisplayString(params.formattedValue)) {
            case JOB_DETAILS_COLUMN_NAMES.LAUNCH:
                // Rules for launching the different job types    
                switch (row_id) {
                    case GRID_MODES.WEED_PRESSURE_DECISION:
                        // Don't allow weed pressure decision to be launched if the job id is not set
                        if (this.state.point_labeling_job[JOB_KEYS.ID] === undefined) {
                            this.showToast("Point Labeling Job must be created before making a Weed Pressure Decision.");
                            return;
                        } else if (this.state.weed_pressure_decision_is_completed) {
                            this.showToast("Weed Pressure Decision has already been made.");
                            return;
                        }
                        job = this.state.point_labeling_job;
                        break;
                    case JOB_TYPES.POINT_LABELING:
                        // Don't allow point labeling to be launched if Weed Pressure Decision is not complete
                        if (!this.state.weed_pressure_decision_is_completed) {
                            this.showToast("Weed Pressure Decision must be made before beginning " + makeDisplayString(JOB_TYPES.POINT_LABELING) + ".");
                            return;
                        } else if (this.state.point_labeling_job.completed) {
                            // Prompt for confirmation if point labeling is already complete
                            if (!await this.confirm("Point labeling has already been marked as complete. Are you sure you want to relaunch?")) {
                                return;
                            }
                        }
                        job = this.state.point_labeling_job;
                        break;
                    case GRID_MODES.PRE_POLYGON_REVIEW:
                        // Don't allow pre polygon review to be launched if point labeling is not complete
                        if (!this.state.point_labeling_job.completed) {
                            this.showToast(makeDisplayString(JOB_TYPES.POINT_LABELING) + " must be marked as completed before beginning " + makeDisplayString(GRID_MODES.PRE_POLYGON_REVIEW) + ".");
                            return;
                        } else if (this.state.polygon_labeling_job[JOB_KEYS.ID] === undefined) {
                            this.showToast("Polygon Labeling Job must be created before beginning " + makeDisplayString(GRID_MODES.PRE_POLYGON_REVIEW) + ".");
                            return;
                        } else if (this.state.pre_polygon_review_is_completed) {
                            this.showToast("Pre Polygon Review has already been completed.");
                            return;
                        }
                        job = this.state.polygon_labeling_job;
                        break;
                    case JOB_TYPES.POLYGON_LABELING:
                        // Only allow polygon labeling to be launched if the point labeling is complete
                        if (!this.state.pre_polygon_review_is_completed) {
                            this.showToast(makeDisplayString(GRID_MODES.PRE_POLYGON_REVIEW) + " must be completed before beginning " + makeDisplayString(JOB_TYPES.POLYGON_LABELING) + ".");
                            return;
                        } else if (this.state.polygon_labeling_job.completed) {
                            // Prompt for confirmation if polygon labeling is already complete
                            if (!await this.confirm("Polygon labeling has already been marked as complete. Are you sure you want to relaunch?")) {
                                return;
                            }
                        }
                        job = this.state.polygon_labeling_job;
                        break;
                    default:
                        break;        
                }
                // Navigate to the appropriate job type
                const dest_component = (row_id === GRID_MODES.WEED_PRESSURE_DECISION || row_id === GRID_MODES.PRE_POLYGON_REVIEW) ? COMPONENTS.GRID : COMPONENTS.JOBS;
                this.navigateTo(
                    dest_component,
                    { 
                        analytic_id: this.state.analytic_id, 
                        field_name: this.state.field_name,
                        org_name: this.state.org_name,
                        job: JSON.parse(JSON.stringify(job)),
                        job_type: job[JOB_KEYS.JOB_TYPE],
                        grid_mode: row_id,
                    }
                );
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <div className="AnalyticDetails">
                <header className="analytic-details-header">
                    <h2> Analytic ID: {this.state.analytic_id} </h2>
                    <h3> {this.state.org_name}: {this.state.field_name} </h3>
                </header>
                <div className="analytic-details-container">
                    <div className="analytic-details">
                       <header>
                            <h2> Analytic Details </h2>
                       </header>
                       {/* Refresh button */}
                       <div className="refresh-container">
                            <button 
                                className="refresh-button"
                                onClick={this.updateAnalyticDetailsWrapper}
                            >
                                <CachedIcon id={this.analytic_details_refresh_icon_id}/>
                            </button>
                        </div>
                        {/* Analytic Status */}
                        <div className={"analytic-status " + this.state.analytic_status}>
                            <strong>Analytic Status:</strong> {makeDisplayString(this.state.analytic_status)}
                        </div>

                        {/* Submit form for patching analytic priority (number) and the allow_external_annotations flag */}
                        <form
                        className="analytic-patch-form"
                            onSubmit={async (event) => {
                                event.preventDefault();
                                const priority = parseInt(event.target[0].value);
                                const allow_external_annotation = event.target[1].checked;
                                if (await patchAnalytic(this.state.analytic_id, priority, allow_external_annotation)) {
                                    await this.updateAnalyticDetailsWrapper();
                                    this.showToast("Analytic updated successfully.");
                                } else {
                                    this.showToast("Failed to update analytic.");
                                }
                            }}
                        >
                            {/* Priority */}
                            <label htmlFor="priority">Priority:</label>
                            <input
                                type="number"
                                id="priority"
                                name="priority"
                                min="1"
                                max="99"
                                defaultValue={this.analytic[ANALYTIC_KEYS.ANALYTIC_PRIORITY]}
                            />
                            {/* Allow External Annotations */}
                            <label htmlFor="allow-external-annotation">Allow External Annotation:</label>
                            <input
                                type="checkbox"
                                id="allow-external-annotation"
                                name="allow-external-annotation"
                                defaultChecked={this.analytic[ANALYTIC_KEYS.ALLOW_EXTERNAL_ANNOTATION]}
                            />
                            <button
                                type="submit"
                                className="button"
                            >
                                Update Analytic
                            </button>
                        </form>

                        {/* Mark as Abandoned */}
                        { this.state.analytic_status !== ANALYTIC_STATUSES.ABANDONED &&
                            <div className="mark-abandoned-container">
                                <button 
                                    className={"button " + ANALYTIC_STATUSES.ABANDONED}
                                    onClick={async () => {
                                        if (await this.confirm("Are you sure you want to mark this analytic as abandoned?")) {
                                            if (await markAnalyticAsAbandoned(this.state.analytic_id)) {
                                                await this.updateAnalyticDetailsWrapper();
                                                this.showToast("Analytic marked as abandoned.");
                                            } else {
                                                this.showToast("Failed to mark analytic as abandoned.");
                                            }
                                        }
                                    }}
                                    title="Mark the analytic as abandoned."
                                >
                                    Mark as Abandoned
                                </button>
                            </div>
                        }
                    </div>
                    <div className="job-details">
                        <header> 
                            <h2> Job Details </h2>
                        </header>
                        {/* Refresh button */}
                        <div className="refresh-container">
                            <button 
                                className="refresh-button"
                                onClick={this.updateJobDetailsWrapper}
                            >
                                <CachedIcon id={this.job_details_refresh_icon_id}/>
                            </button>
                        </div>
                        <DataGrid 
                            rows={this.state.job_details_rows} 
                            columns={this.state.job_details_columns}
                            onCellClick={this.handleCellClick}
                            hideFooter={true}
                            style={{ backgroundColor: "white" }}
                        />
                    </div>
                    <div className="audit-logs">
                        <header> 
                            <h2> Audit Logs </h2>
                        </header>
                        <div className="refresh-container">
                            <button 
                                className="refresh-button"
                                onClick={this.updateAuditLogsWrapper}
                            >
                                <CachedIcon id={this.audit_logs_refresh_icon_id}/>
                            </button>
                        </div>
                        <DataGrid 
                            rows={this.state.audit_logs_rows} 
                            columns={this.state.audit_logs_columns}
                            hideFooter={true}
                            style={{ backgroundColor: "white" }}
                            initialState={{
                                columns: {
                                    columnVisibilityModel: this.auditLogsColumnVisibilityModel,
                                },
                            }}
                        />
                    </div>
                </div>
                


            </div>
        )
    }
}

export default AnalyticDetails;
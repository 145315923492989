// React component for ULabel, a wrapper around the ULabel library
import "../css/Ulabel.css";
import { Component } from "react";
import { DEFAULT_USER } from "../utils/constants";
import { ULabel } from "ulabel";

class Ulabel extends Component {

    constructor(props) {
        super(props);

        this.initProps(props);
     
        // Bind functions
        this.initProps            = this.initProps.bind(this);
        this.startULabel          = this.startULabel.bind(this);
        this.attachAnnosToSubtask = this.attachAnnosToSubtask.bind(this);
        this.reload               = this.reload.bind(this);
        this.clearAllAnnotations  = this.clearAllAnnotations.bind(this);

        // Start ulabel
        this.startULabel();
    }

    initProps(props) {
        // Initialize props
        this.image              = props.image              || null;
        this.mask               = props.mask               || null;
        this.username           = props.username           || DEFAULT_USER;
        this.annotations        = props.annotations        || null;
        this.other_ulabel_props = props.other_ulabel_props || {};
        
        this.rgb_displayed = true;
        
        // Deep copy 
        this.subtasks = JSON.parse(JSON.stringify(props.subtasks)) || {};
    }

    async startULabel() {
        if (this.image == null) {
            alert("No image provided, canceling ulabel job...");
            return;
        }

        await this.attachAnnosToSubtask();

        await setTimeout(() => {}, 1); // idk why but unless we wait ulabel can't load the image

        // Initial ULabel configuration
        // https://github.com/SenteraLLC/ulabel/blob/main/api_spec.md
        this.ulabel = new ULabel({
            "container_id": "container",      
            "image_data": this.image,
            "username": this.username,
            "subtasks": this.subtasks,
            ...this.other_ulabel_props, // Spread operator to add any other props      
        });

        // Wait for ULabel instance to finish initialization
        this.ulabel.init(() => {});
    }

    async attachAnnosToSubtask(reload=false) {
        // In config.json "subtasks" field, the user should specify the
        // key used to store the annotations in their json as the "resume_from" field. 
        // Here we use that key to put the actual annotations in the resume_from of
        // the subtask.
        let resume_from_key;
        if (this.annotations != null) {
            for (let task in this.subtasks) {
                if (this.subtasks[task]["resume_from"] !== null) {
                    resume_from_key = this.subtasks[task]["resume_from"]; // User defined key
                } else {
                    resume_from_key = task; // Default to task key
                }

                if (resume_from_key in this.annotations) {
                    this.subtasks[task]["resume_from"] = this.annotations[resume_from_key];

                    if (reload) { // On reload, ulabel is already running so we update in place
                        this.ulabel.set_annotations(this.annotations[resume_from_key], task);
                    }
                }
            }
        }
    }

    clearAllAnnotations(subtasks) {
        for (let task in subtasks) {
            this.ulabel.set_annotations({}, task);
        }
    }

    reload(props, oldSubtasks) {
        this.initProps(props);
        this.ulabel.swap_frame_image(this.image);
        this.clearAllAnnotations(oldSubtasks);
        this.attachAnnosToSubtask(true);
    }

    keydownEventHandler = (e) => {
        if (e.key === "b" && this.mask !== null) {
            if (this.rgb_displayed) {
                this.ulabel.swap_frame_image(this.mask);
            } else {
                this.ulabel.swap_frame_image(this.image);
            }
            this.rgb_displayed = !this.rgb_displayed;
        } else if (e.key === "h" || e.key === "H") {
            // Click the vanish button
            document.getElementById("annotation-resize-v").click();
        } else if (e.key === "g" || e.key === "G") {
            // Macro to switch subtasks, vanish, and then switch back
            // Get all the children of the class `toolbox-tabs`
            let toolboxTabs = document.getElementsByClassName("toolbox-tabs")[0].children;
            // If there is a second child, click it
            if (toolboxTabs.length > 1) {
                toolboxTabs[1].children[0].click();
                // Then click the vanish button
                document.getElementById("annotation-resize-v").click();
                // Then click the first child
                toolboxTabs[0].children[0].click();
            }
        }    
    }

    render() {
        return (
            <div
                id="container" 
                className="ulabel-container"
            ></div>
        )
    }

    componentDidUpdate(prevProps) { // Triggers when props change
        this.reload(this.props, prevProps.subtasks);
    }

    componentDidMount() {
        // Add event listeners (ulabel adds its own)
        document.addEventListener("keydown", this.keydownEventHandler);
    }

    componentWillUnmount() {
        // Remove event listeners (including ulabel's)
        document.removeEventListener("keydown", this.keydownEventHandler);
        this.ulabel.remove_listeners();
    }
}

export default Ulabel;
// Utils for parsing audit log 
import { 
    ANALYTIC_STATUSES,
    AUDIT_LOG_DETAILS_KEYS,
    AUDIT_LOG_DISPLAY_NAMES,
    AUDIT_LOG_EVENT_NAMES,
    AUDIT_LOG_KEYS,
    IMAGE_STATUSES,
} from "../utils/constants";
import { emailToDisplayName, makeDisplayString } from "./display";

/**
 * Get the most recent annotator from an image's audit logs
 * 
 * @param {Array} audit_logs List of audit logs
 * @returns {string} Annotator name
 */
export function getMostRecentAnnotatorFromAuditLogs(audit_logs) {
    return emailToDisplayName(
        audit_logs.reverse().find(
            log => getAuditLogDisplayName(log) === AUDIT_LOG_DISPLAY_NAMES.IMAGE_ANNOTATING_COMPLETED
        )?.[AUDIT_LOG_KEYS.CREATED_BY_EMAIL]
    );
}

/**
 * Get the display name of an audit log event
 * 
 * @param {Object} log Audit log
 * @returns {string} Display name
 */
export function getAuditLogDisplayName(log) {
    // For STATUS_CHAGNED events, we have to dig into the details to derive the display name
    let old_status, new_status;
    switch (log[AUDIT_LOG_KEYS.EVENT_NAME]) {
        // Image State Transitions
        case AUDIT_LOG_EVENT_NAMES.IMAGE_STATUS_CHANGED:
            old_status = log[AUDIT_LOG_KEYS.DETAILS][AUDIT_LOG_DETAILS_KEYS.IMAGE_STATUS_OLD];
            new_status = log[AUDIT_LOG_KEYS.DETAILS][AUDIT_LOG_DETAILS_KEYS.IMAGE_STATUS_NEW];
            
            switch (new_status) {
                // Ready to Annotate
                case IMAGE_STATUSES.READY_TO_ANNOTATE:
                    if (old_status === IMAGE_STATUSES.EXCLUDED) {
                        return AUDIT_LOG_DISPLAY_NAMES.IMAGE_INCLUDED;
                    } else if (old_status === IMAGE_STATUSES.REVIEWING) {
                        return AUDIT_LOG_DISPLAY_NAMES.IMAGE_REVIEW_FAILED;
                    }
                    return AUDIT_LOG_DISPLAY_NAMES.IMAGE_UNLOCKED;
                // Excluded
                case IMAGE_STATUSES.EXCLUDED:
                    return AUDIT_LOG_DISPLAY_NAMES.IMAGE_EXCLUDED;
                // Locked
                case IMAGE_STATUSES.ANNOTATING:
                case IMAGE_STATUSES.REVIEWING:
                    return AUDIT_LOG_DISPLAY_NAMES.IMAGE_LOCKED;
                // Ready to Review
                case IMAGE_STATUSES.READY_TO_REVIEW:
                    if (old_status === IMAGE_STATUSES.ANNOTATING) {
                        return AUDIT_LOG_DISPLAY_NAMES.IMAGE_ANNOTATING_COMPLETED;
                    }
                    return AUDIT_LOG_DISPLAY_NAMES.IMAGE_UNLOCKED;
                // Complete
                case IMAGE_STATUSES.COMPLETE:
                    return AUDIT_LOG_DISPLAY_NAMES.IMAGE_REVIEW_COMPLETED;
                default:
                    // For other transitions, return old status to new status
                    return `${makeDisplayString(old_status)} to ${makeDisplayString(new_status)}`;
            }

        // Analytic State Transitions
        case AUDIT_LOG_EVENT_NAMES.ANALYTIC_STATUS_CHANGED:
            old_status = log[AUDIT_LOG_KEYS.DETAILS][AUDIT_LOG_DETAILS_KEYS.ANALYTIC_STATUS_OLD];
            new_status = log[AUDIT_LOG_KEYS.DETAILS][AUDIT_LOG_DETAILS_KEYS.ANALYTIC_STATUS_NEW];

            // Catch new statuses that are self-explanatory, FULFILLED and ABANDONED
            if (new_status === ANALYTIC_STATUSES.FULFILLED || new_status === ANALYTIC_STATUSES.ABANDONED) {
                // capitalize the status
                return "Analytic " + makeDisplayString(new_status);
            }

            // Otherwise check the old status
            switch (old_status) {
                case ANALYTIC_STATUSES.WEED_PRESSURE_DECISION:
                    // Pressure is low if we sent it to point labeling
                    if (new_status === ANALYTIC_STATUSES.POINT_LABELING) {
                        return AUDIT_LOG_DISPLAY_NAMES.WEED_PRESSURE_LOW;
                    }
                    // Otherwise it must be high
                    return AUDIT_LOG_DISPLAY_NAMES.WEED_PRESSURE_HIGH;
                case ANALYTIC_STATUSES.POINT_LABELING:
                    // Job was completed
                    return AUDIT_LOG_DISPLAY_NAMES.POINT_LABELING_COMPLETED;
                case ANALYTIC_STATUSES.PRE_POLYGON_REVIEW:
                    // Job was completed
                    return AUDIT_LOG_DISPLAY_NAMES.PRE_POLYGON_REVIEW_COMPLETED;
                case ANALYTIC_STATUSES.POLYGON_LABELING:
                    // Job was completed
                    return AUDIT_LOG_DISPLAY_NAMES.POLYGON_LABELING_COMPLETED;
                default:
                    // For other transitions, return old status to new status
                    return `${makeDisplayString(old_status)} to ${makeDisplayString(new_status)}`;
            }

        // Attributes changed
        case AUDIT_LOG_EVENT_NAMES.UPDATE_ATTRIBUTE:
            // details = {
            //     f"{attribute_name}_old": old_value,
            //     f"{attribute_name}_new": new_value,
            // },
            // Get the attribute name and new value
            // Attribute name is everything before the _old or _new
            const old_key = Object.keys(log[AUDIT_LOG_KEYS.DETAILS])[0];
            const attribute_name = old_key.substring(0, old_key.lastIndexOf("_"));
            const new_value = log[AUDIT_LOG_KEYS.DETAILS][`${attribute_name}_new`];
            // Return the attribute name and new value
            return `Set ${makeDisplayString(attribute_name)} to ${makeDisplayString(new_value)}`;    

        // For other events, return the event name
        default:
            return makeDisplayString(log[AUDIT_LOG_KEYS.EVENT_NAME]);
    }
}
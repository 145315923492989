import "../css/Login.css";
import { authenticate, confirm, register, resetPassword } from '../utils/cognito';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { LOGIN_MODES } from '../utils/constants';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { PIXEL_PATCH_VERSION } from "../version.js";
import PixelPatchComponent from './PixelPatchComponent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

class Login extends PixelPatchComponent {
    state = {
        login_mode: "",
    }
    constructor(props) {
        super(props);

        // Set the initial state
        this.state = {
            login_mode: LOGIN_MODES.SIGN_IN,
        };

        // Bind functions
        this.loginSuccess = this.loginSuccess.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setLoginMode = this.setLoginMode.bind(this);
    }

    /**
     * Save the username
     * 
     * @param {string} user username
     * @param {string[]} user_groups user groups
     */
    loginSuccess = (user, user_groups) => {
        this.setUser(user, user_groups);
    }

    /**
     * Handle verifying the user's email address with a confirmation code
     * 
     * @param {string} email email address
     * @param {string} password password
     */
    handleVerification = async (email, password) => {
        // Confirm the sign up by sending the confirmation code
        let code = prompt("Please enter the confirmation code sent to your email: " + email);
        
        // Loop until the user enters the correct code or cancels
        while (code !== null) {
            if (await confirm(email, password, code)) {
                // If the confirmation is successful, break the loop
                return this.loginSuccess(email, JSON.parse(localStorage.getItem("user_groups")));
            }
            // If the confirmation is unsuccessful, prompt the user for the code again
            code = prompt("Invalid code. Please enter the confirmation code sent to your email: " + email);
        }
    }   

    /**
     * Handle the form submission
     * 
     * @param {*} event submit event
     */
    handleSubmit = (event) => {
        // Get the form data
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get("email");
        const password = data.get("password");

        // Handle the login mode 
        switch (this.state.login_mode) {
            // Sign in with an existing account
            case LOGIN_MODES.SIGN_IN:
                authenticate(email, password).then((result) => {
                    this.loginSuccess(email, JSON.parse(localStorage.getItem("user_groups")));
                }).catch((error) => {
                    // If user is not confirmed, prompt for confirmation code
                    if (error.code === "UserNotConfirmedException") {
                        this.handleVerification(email, password);
                    } else {
                        this.showToast("Login failed: " + error.message);
                    }
                });
                break;
            // Sign up for a new account and confirm the registration
            case LOGIN_MODES.SIGN_UP:
                register(email, password).then(() => {
                    this.handleVerification(email, password);
                }).catch((error) => {
                    this.showToast("Registration failed: " + error.message);
                });
                break;
            case LOGIN_MODES.RESET_PASSWORD:
                resetPassword(email, password).then((result) => {
                    this.showToast("Password reset successful. Please Login with your new password.");
                }).catch((error) => {
                    this.showToast("Password reset failed: " + error.message);
                });
                break;
            default:
                break;
        }
        
    };

    /**
     * Set the login mode
     * 
     * @param {string} mode login mode
     */
    setLoginMode = (mode) => {
        this.setState({ login_mode: mode });
    }

    render () {
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        { this.state.login_mode }
                    </Typography>
                    <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={this.state.login_mode === LOGIN_MODES.RESET_PASSWORD ? "New Password" : "Password"}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            title="Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character."
                        />
                        {/* When signing up or reseting password, list the password requirements */}
                        { (this.state.login_mode === LOGIN_MODES.SIGN_UP || this.state.login_mode === LOGIN_MODES.RESET_PASSWORD) &&
                            <Typography variant="body2" color="text.secondary">
                                Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.
                            </Typography>
                        }
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            { this.state.login_mode }
                        </Button>
                        {/* When in Sign In mode or Reset Password Mode, show the link to Sign Up mode */}
                        { (this.state.login_mode === LOGIN_MODES.SIGN_IN || this.state.login_mode === LOGIN_MODES.RESET_PASSWORD) &&
                            <div className="login-mode-container">
                                <button
                                    type="button" 
                                    onClick={() => this.setLoginMode(LOGIN_MODES.SIGN_UP)}
                                    className="login-mode-button"
                                >
                                    { "Don't have an account? " + LOGIN_MODES.SIGN_UP }
                                </button>
                            </div>
                        }
                        {/* When in Sign Up mode, show the link to Sign In mode */}
                        { this.state.login_mode === LOGIN_MODES.SIGN_UP &&
                            <div className="login-mode-container">
                                <button
                                    type="button" 
                                    onClick={() => this.setLoginMode(LOGIN_MODES.SIGN_IN)}
                                    className="login-mode-button"
                                >
                                    { "Already have an account? " + LOGIN_MODES.SIGN_IN }
                                </button>
                            </div>
                        }
                        {/* When in Sign In mode, show a link to Reset Password */}
                        { this.state.login_mode === LOGIN_MODES.SIGN_IN &&
                            <div className="login-mode-container">
                                <button
                                    type="button" 
                                    onClick={() => this.setLoginMode(LOGIN_MODES.RESET_PASSWORD)}
                                    className="login-mode-button"
                                >
                                    Forgot your password?
                                </button>
                            </div>
                        }
                    </Box>
                </Box>
                <Typography variant="body2" color="text.secondary" align="center">
                    {"PixelPatch v" + PIXEL_PATCH_VERSION}
                </Typography>
            </Container>
        );
    }   
}

export default Login;
import "./css/App.css";
import { 
    ANALYTIC_STATUS_COLOR_MAP,
    COMMON_COMPONENTS_LIST,
    COMPONENTS, 
    COMPONENTS_LIST, 
    DEFAULT_USER, 
    EXTERNAL_COMPONENTS_LIST,
    HIDDEN_COMPONENTS_LIST,
    IMAGE_STATUS_COLOR_MAP,
    INTERNAL_COMPONENTS_LIST,
    TOAST_DEFAULT_DURATION,
    TOAST_POSITION_KEYS, 
    USER_GROUPS,
} from "./utils/constants.js";
import { BrowserRouter, Link, Route, Routes, } from "react-router-dom";
import { Component, Fragment } from "react";
import AnalyticDetails from "./components/AnalyticDetails.js";
import Analytics from "./components/Analytics.js";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from "@mui/icons-material/Close";
import ExternalHomepage from "./components/ExternalHomepage.js";
import Grid from "./components/Grid.js";
import Home from "./components/Home.js";
import IconButton from "@mui/material/IconButton";
import Jobs from "./components/Jobs.js";
import Login from "./components/Login.js";
import Logout from "./components/Logout.js";
import { Navigate } from "react-router-dom";
import PixelPatchLabeler from "./components/PixelPatchLabeler.js";
import Snackbar from "@mui/material/Snackbar";
import { SnackbarContent } from "@mui/material";
import axios from "axios"
import { getAndClearNavigationArguments } from "./utils/navigation.js";
import logo from "./assets/sentera_logo.png";
import { v4 as uuid } from "uuid";

// Link keys to components
const COMPONENT_KEY_MAP = {
    [COMPONENTS.LOGIN]: (props) => {return <Login {...props}/>},
    [COMPONENTS.LOGOUT]: (props) => {return <Logout {...props}/>},
    [COMPONENTS.HOME]: (props) => {return <Home {...props}/>},
    [COMPONENTS.EXTERNAL_HOMEPAGE]: (props) => {return <ExternalHomepage {...props}/>},
    [COMPONENTS.ANALYTICS]: (props) => {return <Analytics {...props}/>},
    [COMPONENTS.GRID]: (props) => {return <Grid {...props}/>},
    [COMPONENTS.JOBS]: (props) => {return <Jobs {...props}/>},
    [COMPONENTS.ANALYTIC_DETAILS]: (props) => {return <AnalyticDetails {...props}/>},
    [COMPONENTS.PIXEL_PATCH_LABELER]: (props) => {return <PixelPatchLabeler {...props}/>},
}

class App extends Component {
    state = {
        // Global props that can be updated by child components
        // { component_name <string>: props <object> }
        // The default user, unless a user is logged in in local storage
        user: localStorage.getItem("user") || DEFAULT_USER,
        user_groups: JSON.parse(localStorage.getItem("user_groups")) || [],
        components_list: [], // List of components to build in the app
        toast_open: false,
        toast_message: "",
        toast_is_confirm: false,
        toast_vertical: TOAST_POSITION_KEYS.VERTICAL_TOP,
        toast_horizontal: TOAST_POSITION_KEYS.HORIZONTAL_CENTER,
        toast_callback: null,
        toast_id: null,
    }
    toast_confirmed = null; // Promise for the toast confirmation, returns true on success
    constructor(props) {
        super(props);

        // Attach styles for status colors
        for (const COLOR_MAP of [IMAGE_STATUS_COLOR_MAP, ANALYTIC_STATUS_COLOR_MAP]) {
            for (const [status, color] of Object.entries(COLOR_MAP)) {
                const style = document.createElement("style");
                style.innerHTML = `div.${status} { background-color: ${color}; }`;
                document.getElementsByTagName("head")[0].appendChild(style);
            }
        }

        this.showToast = this.showToast.bind(this);
        this.closeToast = this.closeToast.bind(this);
        this.updateGlobalProps = this.updateGlobalProps.bind(this);
        this.getFilteredGlobalProps = this.getFilteredGlobalProps.bind(this);
        this.buildComponentsList = this.buildComponentsList.bind(this);
        this.logoOnClick = this.logoOnClick.bind(this);
        this.isLoggedIn = this.isLoggedIn.bind(this);
        this.isInternalUser = this.isInternalUser.bind(this);
        this.isExternalUser = this.isExternalUser.bind(this);

        // Add the updateGlobalProps function to the global props
        for (const component_name of COMPONENTS_LIST) {
            this.state[component_name] = {
                "updateGlobalProps": this.updateGlobalProps,
                "showToast": this.showToast,
            };
        }

        // Build the components list if the user is logged in
        if (this.isLoggedIn()) {
            this.state.components_list = this.buildComponentsList();
        }
    }

    /**
     * Show a toast message, with an optional duration and callback
     * 
     * @param {string} message toast message 
     * @param {number} duration number of milliseconds to show the toast. null for no set duration
     * @param {boolean} is_confirm whether the toast is a confirmation toast
     * @param {string} vertical vertical position of the toast
     * @param {string} horizontal horizontal position of the toast
     * @param {function} callback function to call when the toast is closed
     * @returns {Promise<boolean>} True on confirmation success
     */
    showToast = async (
        message, 
        duration = TOAST_DEFAULT_DURATION, 
        is_confirm = false,
        vertical = TOAST_POSITION_KEYS.VERTICAL_TOP,
        horizontal = TOAST_POSITION_KEYS.HORIZONTAL_CENTER,
        callback = null
    ) => {
        // Generate a unique ID for the toast
        const toast_id = uuid();
        this.setState({
            toast_open: true,
            toast_message: message,
            toast_is_confirm: is_confirm,
            toast_vertical: vertical,
            toast_horizontal: horizontal,
            toast_callback: callback,
            toast_id: toast_id,
        });

        // If the toast is a confirmation toast, return a promise
        if (is_confirm) {
            this.toast_confirmed = {};
            this.toast_confirmed.promise = new Promise((resolve) => {
                this.toast_confirmed.resolve = resolve;
            });
            return this.toast_confirmed.promise;
        }

        // If no duration is set, don't close the toast 
        if (duration === null) return;

        setTimeout(() => {
            // Don't close the toast if the toast_id has changed
            if (this.state.toast_id === toast_id) {
                this.closeToast(callback);
            }
        }, duration);
    }

    /**
     * Close the toast
     * 
     * @param {function} callback function to call when the toast is closed
     */
    closeToast = (callback = null, confirmed = false) => {
        // Close the toast
        this.setState({
            toast_open: false,
        });
        // Call the callback function
        if (callback !== null) {
            callback();
        }
        // Resolve the promise if it exists
        if (this.toast_confirmed !== null) {
            this.toast_confirmed.resolve(confirmed);
            this.toast_confirmed = null;
        }   
    }

    /**
     * Handler for allowing child components to update the global props
     */
    updateGlobalProps = (component_name, component_props, user = null, user_groups = null) => {
        // Handle arguments
        if (user_groups === null) {
            user_groups = this.state.user_groups;
        }
        if (user === null) {
            user = this.state.user;
        }
        component_props.updateGlobalProps = this.updateGlobalProps;
        component_props.showToast = this.showToast;
        
        // Update the state with the new props
        this.setState({
            [component_name]: component_props,
            user: user,
            user_groups: user_groups,
            components_list: user !== DEFAULT_USER ? this.buildComponentsList(user_groups) : [],
        });
    }

    /**
     * Get the props for a specific component
     * 
     * @param {string} component_name The name of the component
     * @returns {Object} The filtered global props
     */
    getFilteredGlobalProps = (component_name) => {
        return {
            ...this.state[component_name], 
            user: this.state.user
        };
    }

    /**
     * Build the components list based on the user groups
     * 
     * @param {Array} user_groups User groups
     * @returns {Array} List of components
     */
    buildComponentsList = (user_groups = null) => {
        if (user_groups === null) {
            user_groups = this.state.user_groups;
        }

        // Start with the common components list
        let components_list = COMMON_COMPONENTS_LIST;

        // Get the auth token from local storage
        axios.defaults.headers.common.Authorization = localStorage.getItem("auth");

        // Add the internal and external components to the components list
        if (this.isInternalUser(user_groups)) {
            components_list = components_list.concat(INTERNAL_COMPONENTS_LIST);
        } 
        if (this.isExternalUser(user_groups)) {
            components_list = components_list.concat(EXTERNAL_COMPONENTS_LIST);
        }
        return components_list;
    }

    /**
     * Click on the logo to go to the home page
     */
    logoOnClick = () => {
        // Do nothing if not logged in
        if (!this.isLoggedIn()) return;

        // Click on the home button to go to the home page
        let link = document.getElementById(COMPONENTS.HOME + "-link");
        link.click();
    }

    /**
     * Determine if the user is logged in
     * 
     * @returns {boolean} True if the user is logged in
     */
    isLoggedIn = () => {
        return this.state.user !== DEFAULT_USER;
    }

    /**
     * Determine if the user is an internal user
     * 
     * @param {Array} user_groups User groups
     * @returns {boolean} True if the user is an internal user
     */
    isInternalUser = (user_groups) => {
        return user_groups.includes(USER_GROUPS.INTERNAL);
    }

    /**
     * Determine if the user is an external user
     * 
     * @param {Array} user_groups User groups
     * @returns {boolean} True if the user is an external user
     */
    isExternalUser = (user_groups) => {
        return user_groups.includes(USER_GROUPS.EXTERNAL);
    }

    /**
     * Use localStorage navigation arguments if they exist, and then clear them
     * 
     */
    componentDidMount() {
        // Get the navigation arguments from local storage
        const navigation_args = getAndClearNavigationArguments();
        if (navigation_args.component_name !== null) {
            this.updateGlobalProps(navigation_args.component_name, navigation_args.component_props);
            // Click on the link to navigate to the component
            let link = document.getElementById(navigation_args.component_name + "-link");
            link.click();
        }
    }

    render() {
        return (
            <BrowserRouter>
                <div className="App">
                    {/* Navbar Menu */}
                    <div id="menu" className="menu"> 
                        {/* Icon with link to homepage */}
                        <img id="menu-logo" src={logo} alt="Logo" onClick={this.logoOnClick}/>
                        {/* If logged in, show the user name as a link to the logout page */}
                        { this.isLoggedIn() &&
                            <Link id="menu-user" className="Link" to={"/" + COMPONENTS.LOGOUT}>
                                <p title={ "User Group(s): " + this.state.user_groups.join(", ") }> 
                                    {this.state.user} 
                                </p>
                            </Link>
                        }
                        { this.isLoggedIn() &&
                            <div className="link-holder">
                                {this.state.components_list.map(component => (
                                    // Only show the link if it's not in the hidden list
                                    <Link 
                                        className={"Link " + (HIDDEN_COMPONENTS_LIST.includes(component) ? "hidden" : "")} 
                                        id={component + "-link"}
                                        key={component + "-link"} 
                                        to={"/" + component}>
                                        <h2> {component} </h2>
                                    </Link>
                                ))}
                            </div>
                        }
                    </div>

                    {/* Create a route for each component */}
                    <Routes>
                        { this.isLoggedIn() ?
                            // Default to the home page if logged in
                            <Route 
                                path="/" 
                                element={COMPONENT_KEY_MAP[COMPONENTS.HOME](this.getFilteredGlobalProps(COMPONENTS.HOME))}
                                key="default-route">
                            </Route> :
                            // Default to the login page if not logged in
                            <Route 
                                path="/" 
                                element={COMPONENT_KEY_MAP[COMPONENTS.LOGIN](this.getFilteredGlobalProps(COMPONENTS.LOGIN))}
                                key="login-route">
                            </Route>                  
                        }                      
                        {/* Add the other routes */}
                        { this.isLoggedIn() && this.state.components_list.map(component => (
                            <Route 
                                path={"/" + component} 
                                element={COMPONENT_KEY_MAP[component](this.getFilteredGlobalProps(component))}
                                key={component + "-route"}>
                            </Route>
                        ))}
                        {/* Redirect invalid routes to "/" */}
                        <Route path="*" element={<Navigate to="/"/>}/>
                    </Routes>
                </div>
                {/* Toast, aka Snackbar, aka a popup. */}
                <Snackbar
                    anchorOrigin={{ vertical: this.state.toast_vertical, horizontal: this.state.toast_horizontal }}
                    open={this.state.toast_open}
                >
                    <SnackbarContent
                        message={this.state.toast_message}
                        style={{backgroundColor: "#2196f3"}}
                        action={
                            <Fragment>
                                {this.state.toast_is_confirm &&
                                    <IconButton
                                        size="medium"
                                        aria-label="confirm"
                                        color="inherit"
                                        onClick={() => {this.closeToast(this.state.toast_callback, true)}}
                                    >
                                        <CheckIcon fontSize="medium"/>
                                    </IconButton>
                                }
                                <IconButton
                                    size="medium"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => {this.closeToast(this.state.toast_callback, false)}}
                                >
                                    <CloseIcon fontSize="medium"/>
                                </IconButton>
                            </Fragment>
                        }
                    />
                </Snackbar>
            </BrowserRouter>
        )
    }
}

export default App;


// Custom component for the PixelPatch applicaiton.
import { COMPONENTS, COMPONENTS_LIST } from "../utils/constants";
import { Component } from "react";

class PixelPatchComponent extends Component {
    constructor(props) {
        super(props);

        // Bind functions
        this.navigateTo = this.navigateTo.bind(this);
        this.setUser = this.setUser.bind(this);
        this.showToast = this.showToast.bind(this);
        this.confirm = this.confirm.bind(this);
    }

    /**
     * Navigate to a component
     * 
     * @param {string} component_name Component name to navigate to
     * @param {object} component_props Props to pass to the component 
     */
    navigateTo = (component_name, component_props = null) => {
        // Reset the global props for the component
        this.resetGlobalProps(component_name, component_props);

        // Wait one millisecond to allow the global props to update
        setTimeout(() => {
            // Click on the link to navigate to the component
            let link = document.getElementById(component_name + "-link");
            link.click();
        }, 1);
    }

    /**
     * Reset the global props for a component
     * 
     * @param {string} component_name Component name to reset the global props for
     * @param {object} component_props New props to set for the component
     */
    resetGlobalProps = (component_name, component_props = null) => {
        if (COMPONENTS_LIST.includes(component_name)) {
            // Set the target_component to the component_name
            if (component_props === null) {
                component_props = {}
            }
            // Set the global props
            this.props.updateGlobalProps(component_name, component_props);
        } else {
            console.error("Invalid component name: " + component_name);
        }
    }

    /**
     * Set the user
     * 
     * @param {string} user User to set
     * @param {string[]} user_groups User groups to set
     */
    setUser = (user, user_groups = null) => {
        this.props.updateGlobalProps(COMPONENTS.LOGIN, {}, user, user_groups);
    }

    /**
     * Show a toast message, with an optional duration and callback
     * 
     * @param {string} message toast message 
     * @param {number} duration number of milliseconds to show the toast. null for no set duration
     * @param {string} vertical vertical position of the toast
     * @param {string} horizontal horizontal position of the toast
     * @param {function} callback function to call when the toast is closed
     */
    showToast = async (message, duration, vertical, horizontal, callback) => {
        this.props.showToast(message, duration, false, vertical, horizontal, callback);
    }

    /**
     * Show a confirmation message, with an optional callback
     * 
     * @param {string} message toast message 
     * @param {string} vertical vertical position of the toast
     * @param {string} horizontal horizontal position of the toast
     * @param {function} callback function to call when the toast is closed
     * @returns {Promise<boolean>} True on confirmation success
     */
    confirm = async (message, vertical, horizontal, callback) => {
        return this.props.showToast(message, null, true, vertical, horizontal, callback);
    }
}

export default PixelPatchComponent;
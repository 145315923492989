import "../css/Jobs.css";
import { 
    ANALYTIC_STATUSES,
    AUDIT_LOG_DISPLAY_NAMES,
    AUDIT_LOG_KEYS,
    COMPONENTS,
    GRID_MODES,
    IMAGE_ANNOTATION_STATUSES_LIST,
    IMAGE_ANNOTATION_STATUS_FROM_IMAGE_STATUS, 
    IMAGE_DISPLAY_KEYS,
    IMAGE_DISPLAY_STATUSES,
    IMAGE_KEYS,
    IMAGE_REVIEW_STATUSES_LIST,
    IMAGE_REVIEW_STATUS_FROM_IMAGE_STATUS,
    IMAGE_STATUSES,
    JOB_KEYS,
    JOB_TYPES,
    REVIEW_PENDING,
} from "../utils/constants";
import { completeJob, getNextImage, getNumberOfRemainingImages } from "../api/jobs";
import { 
    emailToDisplayName, 
    makeDisplayString, 
    toggle_refresh_icon_by_id 
} from "../utils/display";
import { getImageAuditLogsByJobID, getImagesByJobID } from "../api/jobs";
import { getJobsByAnalyticID, isPrePolygonReviewComplete } from "../api/analytics";
import { lockImage, unlockImage } from "../api/images";
import CachedIcon from "@mui/icons-material/Cached";
import { DataGrid } from "@mui/x-data-grid";
import PixelPatchComponent from "./PixelPatchComponent";
import { getAuditLogDisplayName } from "../utils/audit_logs";
import { getPixelPatchLabelerPropsFromImage } from "../utils/annotation";
import nav_link_icon from "../assets/nav_link_icon.png";
import point_icon from "../assets/point.svg";
import polygon_icon from "../assets/polygon.svg";

class Jobs extends PixelPatchComponent {
    state = {
        job_type: "",
        job_id: null,
        images: [],
        columns: [],
        jobs_by_job_type: {}, // { <string> job_type: <object> job }
        n_images_per_annotation_status: {},
        n_images_per_review_status: {},
        image_ids: [],
        do_fine_tuning: false,
        weed_annotation_count: 0,
        null_weed_annotation_count_str: "",
        crop_annotation_count: 0,
        null_crop_annotation_count_str: "",
        filterModelItems: [], // https://mui.com/x/react-data-grid/filtering/
    };
    columnVisibilityModel = {};
    cache = {}; // {JOB_TYPES.POINT_LABELING: <state>, JOB_TYPES.POLYGON_LABELING: <state>}
    refresh_icon_id = "jobs-refresh-icon";
    starting_annotation = false;

    constructor(props) {
        super(props);

        this.show_excluded = false;

        // Set the job type
        this.state.job_type = props.job_type || JOB_TYPES.NO_JOB;
        // Get analytic id
        this.analytic_id = props.analytic_id;
        this.field_name = props.field_name;
        this.org_name = props.org_name;
        // Get the user
        this.user = props.user;

        // Bind the functions
        this.fetchJobDetailsWrapper = this.fetchJobDetailsWrapper.bind(this);
        this.fetchJobDetails = this.fetchJobDetails.bind(this);
        this.cacheState = this.cacheState.bind(this);
        this.pointLabelingIsComplete = this.pointLabelingIsComplete.bind(this);
        this.currentJobIsComplete = this.currentJobIsComplete.bind(this);
        this.changeJobType = this.changeJobType.bind(this);
        this.handleCompleteJob = this.handleCompleteJob.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.startAnnotation = this.startAnnotation.bind(this);
        this.getFilterItems = this.getFilterItems.bind(this);
    }

    /**
     * Fetch the job details and images
     */
    async componentDidMount() {
        try {
            await this.fetchJobDetailsWrapper();
        } catch (error) {
            // Catch TypeError from navigating using browser back button
            // TypeError: Cannot read properties of null (reading 'classList')
            // Arises from trying to access the classList of the refresh icon after it has been unmounted
            if (error instanceof TypeError && error.message === "Cannot read properties of null (reading 'classList')") {
                return;
            }
            throw error;
        }
    }

    /**
     * Wrap the fetchJobDetails function to render the refresh icon
     */
    fetchJobDetailsWrapper = async () => {
        toggle_refresh_icon_by_id(this.refresh_icon_id, true);
        await this.fetchJobDetails();
        toggle_refresh_icon_by_id(this.refresh_icon_id, false);
    }

    /**
     * Fetch the job details and images
     */
    fetchJobDetails = async () => {
        // If there is no job type, then return
        if (this.state.job_type === JOB_TYPES.NO_JOB) {
            // Return to Analytics page
            return this.navigateTo(COMPONENTS.ANALYTICS);
        }

        // Get the job details for the analytic
        let jobs = await getJobsByAnalyticID(this.analytic_id);
        // Sort the jobs by job type
        jobs = Object.fromEntries(jobs.map((job) => [job[JOB_KEYS.JOB_TYPE], job]));
        // Get the job id for the job type
        const job_id = jobs[this.state.job_type][JOB_KEYS.ID];
        // Start getting the audit logs
        const all_audit_logs_promise = getImageAuditLogsByJobID(job_id);
        // Get the images for the job
        let images = await getImagesByJobID(job_id, [...IMAGE_DISPLAY_STATUSES, IMAGE_STATUSES.EXCLUDED]);
        if (images.length === 0) {
            let new_state = {
                job_type: this.state.job_type,
                job_id: job_id, 
                images: [], 
                columns: [], 
                jobs_by_job_type: jobs,
                n_images_per_annotation_status: {},
                n_images_per_review_status: {},
                image_ids: [],
                filterModelItems: this.getFilterItems(),
            }
            this.setState(new_state)
            return this.showToast("No images found for " + makeDisplayString(this.state.job_type) + " job.", 2000);
        }
        // Sort images by id
        images.sort((a, b) => a[IMAGE_KEYS.ID] - b[IMAGE_KEYS.ID]);
        let columns = Object.keys(images[0]).map((key) => {
            const column = {
                field: key,
                headerName: makeDisplayString(key),
                flex: 1,
            };

            // https://mui.com/x/react-data-grid/column-definition/#rendering-cells
            // For the analytic status column, show that the cell is clickable
            if (key === IMAGE_KEYS.IMAGE_STATUS) {
                column.renderCell = (params) => (
                    <div className="cursor-pointer">
                        {makeDisplayString(IMAGE_ANNOTATION_STATUS_FROM_IMAGE_STATUS[params.value])}
                    </div>
                );
                column.cellClassName = (params) => {
                    return IMAGE_ANNOTATION_STATUS_FROM_IMAGE_STATUS[params.value];
                }
            }

            // Set the column visibility model
            this.columnVisibilityModel[key] = IMAGE_DISPLAY_KEYS.includes(key);

            return column;
        });
        // Add additional columns: Annotators, Review Status, Reviewers
        const additional_column_names = ["annotators", "review_status", "reviewers"];
        for (const column_name of additional_column_names) {
            columns.push({
                field: column_name,
                headerName: makeDisplayString(column_name),
                flex: 1,
            });

            if (column_name === "review_status") {
                columns[columns.length - 1].renderCell = (params) => (
                    <div className="cursor-pointer">
                        {makeDisplayString(params.value)}
                    </div>
                );
                columns[columns.length - 1].cellClassName = (params) => {
                    return IMAGE_REVIEW_STATUS_FROM_IMAGE_STATUS[params.row[IMAGE_KEYS.IMAGE_STATUS]];
                }
            }
        }
        // Add view-only as the third column, after ID and Filename
        columns.splice(2, 0, {
            field: "view_only",
            headerName: "View (Read Only)",
            flex: 0.5,
            renderCell: () => (
                <div className="cursor-pointer">
                    <img 
                        src={nav_link_icon} 
                        alt="nav-link-icon" 
                        className="nav-link-icon"
                    />
                </div>
            ),
        });

        let n_images_per_annotation_status = Object.fromEntries(IMAGE_ANNOTATION_STATUSES_LIST.map((status) => [status, 0]));
        let n_images_per_review_status = Object.fromEntries(IMAGE_REVIEW_STATUSES_LIST.map((status) => [status, 0]));
        // Wait until all audit logs are fetched
        const all_audit_logs = await all_audit_logs_promise;
        let weed_annotation_count = 0;
        let null_weed_annotation_count_str = "The following images have an unknown number of weed annotations: ";
        let crop_annotation_count = 0;
        let null_crop_annotation_count_str = "The following images have an unknown number of crop annotations: ";
        // Add extra column data to each image
        for (let image of images) {
            // Count the number of weed annotations in non-excluded images
            if (image[IMAGE_KEYS.IMAGE_STATUS] !== IMAGE_STATUSES.EXCLUDED) {
                if (image[IMAGE_KEYS.WEED_ANNOTATION_COUNT] === null) {
                    null_weed_annotation_count_str += image[IMAGE_KEYS.FILENAME] + ", ";
                } else {
                    weed_annotation_count += image[IMAGE_KEYS.WEED_ANNOTATION_COUNT];
                }

                // Count the number of crop annotations
                if (image[IMAGE_KEYS.CROP_ANNOTATION_COUNT] === null) {
                    null_crop_annotation_count_str += image[IMAGE_KEYS.FILENAME] + ", ";
                } else {
                    crop_annotation_count += image[IMAGE_KEYS.CROP_ANNOTATION_COUNT];
                }
            }

            // Format the LOCKED_BY field to display the user's name
            image[IMAGE_KEYS.LOCKED_BY] = emailToDisplayName(image[IMAGE_KEYS.LOCKED_BY]);

            // Get the audit logs for this image
            const audit_logs = all_audit_logs.filter((log) => log[AUDIT_LOG_KEYS.OBJECT_ID] === image[IMAGE_KEYS.ID]);
            // Get the annotators and reviewers from the audit logs
            let annotators = new Set();
            let reviewers = new Set();
            for (let log of audit_logs) {
                const event_name = getAuditLogDisplayName(log);
                if (event_name === AUDIT_LOG_DISPLAY_NAMES.IMAGE_ANNOTATING_COMPLETED) {
                    annotators.add(emailToDisplayName(log[AUDIT_LOG_KEYS.CREATED_BY_EMAIL]));
                } else if (
                    event_name === AUDIT_LOG_DISPLAY_NAMES.IMAGE_REVIEW_COMPLETED ||
                    event_name === AUDIT_LOG_DISPLAY_NAMES.IMAGE_REVIEW_FAILED
                ) {
                    reviewers.add(emailToDisplayName(log[AUDIT_LOG_KEYS.CREATED_BY_EMAIL]));
                }
            }

            image["view_only"] = "view_only";
            image["annotators"] = Array.from(annotators).join(", ");
            image["review_status"] = IMAGE_REVIEW_STATUS_FROM_IMAGE_STATUS[image[IMAGE_KEYS.IMAGE_STATUS]];
            image["reviewers"] = Array.from(reviewers).join(", ");

            // Count the number of images per annotation status
            const annotation_status = IMAGE_ANNOTATION_STATUS_FROM_IMAGE_STATUS[image[IMAGE_KEYS.IMAGE_STATUS]];
            n_images_per_annotation_status[annotation_status] += 1;

            // Count the number of images per review status
            const review_status = IMAGE_REVIEW_STATUS_FROM_IMAGE_STATUS[image[IMAGE_KEYS.IMAGE_STATUS]];
            // We don't show the REVIEW_PENDING status
            if (review_status !== REVIEW_PENDING) {
                n_images_per_review_status[review_status] += 1;
            }
        }
        
        // If a trailing comma exists, remove it
        const annotationCountStrs = [null_weed_annotation_count_str, null_crop_annotation_count_str].map(str => {
            if (str.endsWith(", ")) {
                return str.slice(0, -2) + ". Each image will update once annotation are submitted.";
            } else {
                return "All non-excluded images have updated counts.";
            }
        });
        [null_weed_annotation_count_str, null_crop_annotation_count_str] = annotationCountStrs;

        let new_state = {
            job_type: this.state.job_type,
            job_id: job_id, 
            images: images, 
            columns: columns, 
            jobs_by_job_type: jobs,
            n_images_per_annotation_status: n_images_per_annotation_status,
            n_images_per_review_status: n_images_per_review_status,
            image_ids: images.map((image) => image[IMAGE_KEYS.ID]),
            weed_annotation_count: weed_annotation_count,
            null_weed_annotation_count_str: null_weed_annotation_count_str,
            crop_annotation_count: crop_annotation_count,
            null_crop_annotation_count_str: null_crop_annotation_count_str,
            filterModelItems: this.getFilterItems(),
        }
        this.setState(new_state)
        this.cacheState(new_state);
    }

    // Cache the state for quicker toggling
    cacheState = (state) => {
        this.cache[state.job_type] = state;
    }

    /**
     * Determine whether the point labeling job is complete
     * 
     * @returns {boolean} whether the point labeling job is complete
     */
    pointLabelingIsComplete = () => {
        if (JOB_TYPES.POINT_LABELING in this.state.jobs_by_job_type) {
            return this.state.jobs_by_job_type[JOB_TYPES.POINT_LABELING][JOB_KEYS.COMPLETED];
        } else {
            return false;
        }
    }

    /**
     * Determine whether the current job is complete
     * 
     * @returns {boolean} whether the current job is complete
     */
    currentJobIsComplete = () => {
        if (this.state.job_type in this.state.jobs_by_job_type) {
            return this.state.jobs_by_job_type[this.state.job_type][JOB_KEYS.COMPLETED];
        } else {
            return false;
        }
    }
    /**
     * Switch to the job type if it is not the current job type
     * 
     * @param {string} job_type job type to toggle to 
     */
    changeJobType = async (job_type) => {
        // If the job type is already the current job type, then return
        if (job_type === this.state.job_type) {
            return;
        }
        // Otherwise, toggle the job type
        switch (job_type) {
            case JOB_TYPES.POINT_LABELING:
                // If point labeling is already complete, remind the user before continuing
                if (
                    this.pointLabelingIsComplete() &&
                    await this.confirm("Point Labeling is marked as complete, are you sure you want to proceed?")
                ) {
                    this.state.job_type = JOB_TYPES.POINT_LABELING;
                } else {
                    return;
                }
                break;
            case JOB_TYPES.POLYGON_LABELING:
                // Only allow switching to polygon labeling if the point labeling job and pre polygon review are complete.
                if (!this.pointLabelingIsComplete()) {
                    this.showToast(makeDisplayString(JOB_TYPES.POINT_LABELING) + " must be marked as completed before beginning " + makeDisplayString(JOB_TYPES.POLYGON_LABELING) + ".")
                    return;
                } else if (!await isPrePolygonReviewComplete(this.analytic_id)) {
                    this.showToast(makeDisplayString(ANALYTIC_STATUSES.PRE_POLYGON_REVIEW) + " must be marked as complete before beginning " + makeDisplayString(JOB_TYPES.POLYGON_LABELING) + ".");
                    return;
                }
                this.state.job_type = JOB_TYPES.POLYGON_LABELING;
                break;
            default:
                this.showToast("Invalid job type: " + job_type, 1500);
                return;
        }
        // Fetch the job details and images from cache if available
        if (this.state.job_type in this.cache) {
            this.setState(this.cache[this.state.job_type]);
        } else {
            this.fetchJobDetails();
        } 
    }

    // Verify that all images are either complete or excluded
    // If so, then complete the job
    // Otherwise, prompt the user for confirmation
    handleCompleteJob = async () => {
        if (!this.currentJobIsComplete()) {
            // Check that the number of complete and excluded images is equal to the total number of images
            const n_complete = this.state.n_images_per_review_status[IMAGE_STATUSES.COMPLETE];
            const n_excluded = this.state.n_images_per_review_status[IMAGE_STATUSES.EXCLUDED];
            const all_complete = this.state.images.length === n_complete + n_excluded;
            if (
                // Check if all images are complete, else warn the user
                (
                    all_complete ||
                    await this.confirm("Not all images are complete or excluded. Are you sure you want to complete the job?")
                ) &&
                // Check if the job type is polygon labeling, else warn the user about the fine tuning option 
                (
                    this.state.job_type === JOB_TYPES.POLYGON_LABELING ||
                    await this.confirm("This order will " + (this.state.do_fine_tuning ? "" : "NOT ") + "be sent to fine tuning. Are you sure you want to continue?")
                )
            ) {
                // Mark the job as complete
                if (await completeJob(this.state.job_id, this.state.do_fine_tuning)) {
                    this.showToast("Job marked as complete.", 1500);
                    // Return to analytics
                    this.navigateTo(COMPONENTS.ANALYTICS);
                } else {
                    this.showToast("Error marking job as complete.");
                }
            } 
        }
    }

    /**
     * Set the do_fine_tuning state when the checkbox is clicked
     * 
     * @param {boolean} do_fine_tuning whether to do fine tuning or not
     */
    handleCheckboxChange = (do_fine_tuning) => {
        // If this.state.do_fine_tuning is already set to do_fine_tuning, then flip the value
        if (this.state.do_fine_tuning === do_fine_tuning) {
            this.setState({do_fine_tuning: !do_fine_tuning});
        } else {
            this.setState({do_fine_tuning: do_fine_tuning});
        }
    }

    /**
     * Start annotating or reviewing the next ready image
     * 
     * @param {object} props props to pass to the annotation job
     * @param {boolean} is_review whether to start reviewing the next ready image
     * @param {boolean} view_only whether to start in view-only mode
     */
    startAnnotation = async (props = null, is_review = false, view_only = false) => {
        // Prevent multiple clicks
        if (this.starting_annotation) return this.showToast("Please wait...", 2000);

        // Set the starting_annotation flag to true
        this.starting_annotation = true;
        if (props === null) {
            // Get and lock the next ready image
            let image = await getNextImage(this.state.job_id, is_review);
            if (image === null || image[IMAGE_KEYS.ID] === undefined) {
                this.starting_annotation = false;
                this.showToast("No more images to " + (is_review ? "review." : "annotate."));
                return;
            } else {
                props = await getPixelPatchLabelerPropsFromImage(
                    image,
                    is_review,
                    view_only, 
                    this.state.job_type, 
                    this.analytic_id, 
                    this.field_name, 
                    this.org_name, 
                    true, 
                    this.state.image_ids
                );
            }
        } else if (!view_only) {
            // Lock the image
            if (!await lockImage(props.image_id, is_review)) {
                // If we failed to get the lock, refresh the table
                const promise = this.fetchJobDetailsWrapper()
                this.showToast("Image already locked. Refreshing table...", 4000);
                this.starting_annotation = false;
                return await promise;
            }
            // Now that we succeeded in locking the image, recalculate the number of remaining images
            props.n_remaining_images = await getNumberOfRemainingImages(this.state.job_id, is_review);
        }

        // Navigate to the annotation page
        this.navigateTo(
            COMPONENTS.PIXEL_PATCH_LABELER,
            props,
        );
    }

    getFilterItems = () => {
        let display_statuses = IMAGE_DISPLAY_STATUSES;
        if (this.show_excluded) {
            display_statuses = display_statuses.concat(IMAGE_STATUSES.EXCLUDED);
        }
        return [{
            field: IMAGE_KEYS.IMAGE_STATUS,
            operator: "isAnyOf",
            value: display_statuses,
        }];;
    }

    /**
     * Handle cell click events
     * 
     * @param {*} params Details about the cell and row that was clicked
     * @param {*} event 
     * @param {*} details 
     */
    handleCellClick = async (params, event, details) => {
        let props = await getPixelPatchLabelerPropsFromImage(params.row, false, false, this.state.job_type, this.analytic_id, this.field_name, this.org_name, true, this.state.image_ids);
        switch (params.formattedValue) {
            case IMAGE_STATUSES.READY_TO_ANNOTATE:
                this.startAnnotation(props, false);
                break;
            case IMAGE_STATUSES.ANNOTATING:
            case IMAGE_STATUSES.REVIEWING:
                if (await this.confirm(
                        "Image " + params.row[IMAGE_KEYS.FILENAME] + " is locked by " + params.row[IMAGE_KEYS.LOCKED_BY] + ". " +
                        "\n Are you sure you want to unlock it? Any unsaved changes will be lost."
                    )
                ) {
                    // Unlock
                    if (!await unlockImage(params.row[IMAGE_KEYS.ID])) {
                        this.showToast("Failed to unlock image. Please try again.", 1500);
                    }
                    // Refresh table
                    await this.fetchJobDetailsWrapper();
                }
                break;
            case IMAGE_STATUSES.READY_TO_REVIEW:
                // Only go to review if the clicked cell is in the review_status column
                if (params.field === "review_status") {
                    props.is_review = true;
                    this.startAnnotation(props, props.is_review);
                }
                break;
            case "view_only":
                // If there is a most recent annotator, then is_review is true
                props.is_review = props.most_recent_annotator !== "";
                props.view_only = true;
                this.startAnnotation(props, props.is_review, props.view_only);
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <div className="Jobs">
                <div className="top-container">
                    <div className="manage-images-container">
                        <button
                            className="manage-images-button cursor-pointer"
                            title={"Go to the Grid page and view all images."}
                            onClick={() => {this.navigateTo(
                                COMPONENTS.GRID, 
                                {
                                    analytic_id: this.analytic_id, 
                                    field_name: this.field_name,
                                    org_name: this.org_name,
                                    job: this.state.jobs_by_job_type[this.state.job_type],
                                    // Set mode to MANAGE_IMAGES since we only want to view the images
                                    grid_mode: GRID_MODES.MANAGE_IMAGES,
                                }
                            )}}
                        >
                            Manage Images
                        </button>
                        <div 
                            className="manage-images-value"
                        >
                            {this.state.images.length + " Images"}
                        </div>
                    </div>
                    <div className="job-info-container">
                        <h2> Analytic ID: {this.analytic_id} </h2>
                        <h3> {this.org_name}: {this.field_name} </h3>
                        <div className="job-type-container">
                            {/* Display the the active job with a halo `selected` effect */}
                            {/* Point Labeling */}
                            <div 
                                className={
                                "job-type-container " + 
                                (this.state.job_type === JOB_TYPES.POINT_LABELING ? "selected" : "") +
                                ((this.state.job_type !== JOB_TYPES.POINT_LABELING && this.pointLabelingIsComplete()) ? " unclickable" : "")
                            }>
                                <button 
                                    className="job-type-button cursor-pointer" 
                                    onClick={() => this.changeJobType(JOB_TYPES.POINT_LABELING)}
                                    title={"Switch to " + makeDisplayString(JOB_TYPES.POINT_LABELING)}
                                >
                                    <img src={point_icon} alt="job-icon" className="job-icon"></img>
                                    <p className="job-type-label">{makeDisplayString(JOB_TYPES.POINT_LABELING)}</p>
                                </button>
                            </div>
                            
                            {/* Polygon Labeling */}
                            {/* If Point Labeling is not complete, show the Polygon Labeling button as unclickable */}
                            <div className={
                                "job-type-container " + 
                                (this.state.job_type === JOB_TYPES.POLYGON_LABELING ? "selected" : "") +
                                (this.pointLabelingIsComplete() ? "" : " unclickable")
                            }>
                                <button 
                                    className="job-type-button cursor-pointer" 
                                    onClick={() => this.changeJobType(JOB_TYPES.POLYGON_LABELING)}
                                    title={"Switch to " + makeDisplayString(JOB_TYPES.POLYGON_LABELING)}
                                >
                                    <img src={polygon_icon} alt="job-icon" className="job-icon"></img>
                                    <p className="job-type-label">{makeDisplayString(JOB_TYPES.POLYGON_LABELING)}</p>
                                </button>
                            </div>
                        </div>
                        <div className="controls-container">
                            <div className="annotation-and-review-container">
                                <div className="annotation-container">
                                    <button 
                                        className="annotation-and-review-button cursor-pointer"
                                        onClick={() => this.startAnnotation(null, false)}
                                        title={"Annotate the next ready image."}
                                    >
                                        Annotate
                                    </button>
                                    {Object.keys(this.state.n_images_per_annotation_status).map((key) => {
                                        return (
                                            <div 
                                                key={key}
                                                className={"annotation-and-review-n-images-per-status " + key}
                                            >
                                                <div className="annotation-and-review-value-label">
                                                    {makeDisplayString(key)}
                                                </div>
                                                <div 
                                                    className="annotation-and-review-value">
                                                    {this.state.n_images_per_annotation_status[key]}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="review-container">
                                    <button 
                                        className="annotation-and-review-button cursor-pointer"
                                        onClick={() => this.startAnnotation(null, true)}
                                        title={"Review the next ready image."}
                                    >
                                        Review
                                    </button>
                                    {Object.keys(this.state.n_images_per_review_status).map((key) => {
                                        return (
                                            <div 
                                                key={key}
                                                className={"annotation-and-review-n-images-per-status " + key}
                                            >
                                                <div className="annotation-and-review-value-label">
                                                    {makeDisplayString(key)}
                                                </div>
                                                <div className="annotation-and-review-value">
                                                    {this.state.n_images_per_review_status[key]}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Empty container to help center the job-info-container */}
                    <div className="manage-images-container"/>
                </div>
                {/* Refresh button */}
                <div className="top-controls-container">
                    <div className="refresh-container">
                        <button 
                            className="refresh-button"
                            onClick={this.fetchJobDetailsWrapper}
                        >
                            <CachedIcon id={this.refresh_icon_id}/>
                        </button>
                    </div>
                    <div className="annotation-count-container">
                        <div 
                            className="annotation-count weed"
                            title={this.state.null_weed_annotation_count_str}
                        >
                            Total Number of Weed Annotations: {this.state.weed_annotation_count}
                        </div>
                        { this.state.job_type === JOB_TYPES.POLYGON_LABELING &&
                            <div 
                                className="annotation-count crop"
                                title={this.state.null_crop_annotation_count_str}
                            >
                                Total Number of Crop Annotations: {this.state.crop_annotation_count}
                            </div>
                        }
                    </div>
                    {/* Show abandoned checkbox */}
                    <div className="checkbox-container show-excluded">
                        <label htmlFor="show-excluded">Show Excluded</label>
                        <input 
                            type="checkbox" 
                            id="show-excluded" 
                            name="show-excluded" 
                            onChange={(event) => {
                                this.show_excluded = event.target.checked;
                                this.setState({ filterModelItems: this.getFilterItems() });
                            }}
                        />
                    </div>
                </div>
                {/* https://mui.com/x/api/data-grid/data-grid/ */}
                <DataGrid
                    rows={this.state.images} 
                    columns={this.state.columns}
                    onCellClick={this.handleCellClick} 
                    initialState={{
                        columns: {
                            columnVisibilityModel: this.columnVisibilityModel
                        },
                        pagination: {
                            paginationModel: { pageSize: 40 }
                        },
                    }}
                    pageSizeOptions={[10, 20, 40]}
                    filterModel={{
                        items: this.state.filterModelItems,
                    }}
                    // On filter change, update the filter model items
                    onFilterModelChange={(model) => {
                        this.setState({ filterModelItems: model.items });
                    }}
                >
                </DataGrid>
                <div className="controls-container">
                    <div className="job-complete-container">
                        {/* Checkbox for "Do Fine Tuning? "Yes" / "No" */}
                        {this.state.job_type === JOB_TYPES.POINT_LABELING && 
                            <div className="do-fine-tuning-container">
                                <div className="job-complete-label">
                                    Send to Fine Tuning?
                                </div>
                                <div className="do-fine-tuning-checkbox-container">
                                    <input 
                                        type="checkbox" 
                                        className="do-fine-tuning-checkbox"
                                        title={"Check the box if we want to send to fine tuning."}
                                        checked={this.state.do_fine_tuning === true}
                                        onChange={() => this.handleCheckboxChange(true)}
                                    />
                                </div>
                            </div>
                        }
                        <button
                            className="job-complete-button cursor-pointer"
                            onClick={() => this.handleCompleteJob()}
                            title={"Mark Job as Complete."}
                        >
                            Complete
                        </button>
                        <div 
                            className="job-complete-value"
                            style={{backgroundColor: this.currentJobIsComplete() ? "lightgreen" : "pink"}}
                        >
                            {this.currentJobIsComplete() ? "Complete" : "Not Complete"}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Jobs;
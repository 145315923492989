// Helper component to display images in the Weed Pressure component
import "../css/GridImage.css";
import { Component } from "react";
import { IMAGE_STATUSES } from "../utils/constants";
import { makeDisplayString } from "../utils/display";

class GridImage extends Component {
    image = "";
    image_name = "";
    classes = []; // {class_name: string, svg_overlay: svg}[]
    image_stack = [];

    constructor(props) {
        super(props);

        // Initialize props
        this.image              = props.image;
        this.image_name         = props.image_name;
        this.classes            = props.classes;
        this.image_stack        = props.image_stack || [];
        this.annotation_overlay = props.annotation_overlay || null;
        this.toast_fn           = props.toast_fn || null;
        this.update_callback    = props.update_callback || null;

        // Use state to store current class
        this.default_class = props.default_class || this.classes[0].class_name // Default to first class
        this.state = {
            class: this.default_class
        };

        // Bind functions
        this.changeClass = this.changeClass.bind(this);
    }


    /**
     * Change class to the next class in this.classes
     */
    changeClass = () => {
        // Toggle between the default class and EXCLUDED, or between EXCLUDED and READY_TO_ANNOTATE
        if (this.default_class === IMAGE_STATUSES.EXCLUDED) {
            this.setState((state) => {
                return {class: state.class === IMAGE_STATUSES.EXCLUDED ? IMAGE_STATUSES.READY_TO_ANNOTATE : IMAGE_STATUSES.EXCLUDED}
            });
        } else if ([IMAGE_STATUSES.ANNOTATING, IMAGE_STATUSES.REVIEWING].includes(this.default_class) && this.toast_fn) {
            // Don't change class for locked images
            this.toast_fn("This image is currently locked and cannot be excluded.", 1500);
        } else {
            this.setState((state) => {
                return {class: state.class === this.default_class ? IMAGE_STATUSES.EXCLUDED : this.default_class}
            });
        }
            
    }
    

    render() {
        return (
            <div 
                className={"GridImage " + this.state.class}
                onClick={this.changeClass}
                id={this.image_name}
            >
                <div className="img-container">
                    <img 
                        src={this.image} 
                        alt={this.image_name}
                        id={this.image_name + "-image"}
                        className={"hover-target"}>
                    </img>
                    {this.image_stack.map(image => (
                        <img
                            src={image}
                            alt={this.image_name + "_layer" + this.image_stack.indexOf(image)}
                            id={this.image_name + "_layer" + this.image_stack.indexOf(image)}
                            key={this.image_name + "_layer" + this.image_stack.indexOf(image)}
                            className="hidden hover-target">
                        </img>
                    ))}
                    {/* Image Annotation Overlay */}
                    <img 
                        src={this.annotation_overlay} 
                        className="annotation-overlay hover-target"
                        style={{filter: this.annotation_overlay === null ? "opacity(0)" : "opacity(1)"}} 
                        alt={this.image_name + "_annotation_overlay"}
                        id={this.image_name + "-annotation-overlay"}>
                    </img>
                    {/* Image Class Overlay */}
                    <img 
                        src={this.classes.find(x => x.class_name === this.state.class).svg_overlay} 
                        className="class-overlay hover-target" 
                        alt={this.image_name + "_class_overlay"}
                        id={this.image_name + "-class-overlay"}>
                    </img>
                </div>
                <p className="image-name">{this.image_name}</p>
                <p className="class-name">{this.state.class === "not_used" ? "Image Added on Submit" : makeDisplayString(this.state.class)}</p>
            </div>        
        )
    }

    componentDidUpdate() {
        if (this.update_callback) {
            this.update_callback();
        }
    }
}

export default GridImage;
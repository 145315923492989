// Navigation utility functions
import { NAVIGATION_DESTINATION_KEY, NAVIGATION_PROPS_KEY } from "./constants";

/**
 * Save navigation arguments to local storage for use when the app is reloaded
 * 
 * @param {string} component_name Component name to navigate to
 * @param {object} component_props Props to pass to the component 
 */
export function saveNavigationArguments(component_name, component_props) {
    localStorage.setItem(NAVIGATION_DESTINATION_KEY, component_name);
    localStorage.setItem(NAVIGATION_PROPS_KEY, JSON.stringify(component_props));
}

/**
 * Get the navigation arguments from local storage and clear them
 * 
 * @returns {object} The navigation arguments
 */
export function getAndClearNavigationArguments() {
    const component_name = localStorage.getItem(NAVIGATION_DESTINATION_KEY);
    const component_props = JSON.parse(localStorage.getItem(NAVIGATION_PROPS_KEY));
    localStorage.removeItem(NAVIGATION_DESTINATION_KEY);
    localStorage.removeItem(NAVIGATION_PROPS_KEY);
    return {
        component_name: component_name,
        component_props: component_props,
    };
}
import "../css/ExternalHomepage.css";
import { COMPONENTS, JOB_TYPES } from "../utils/constants";
import {
    getNextImageToAnnotate,
    getNumberOfImagesReadyToAnnotate,
} from "../api/images";
import CachedIcon from "@mui/icons-material/Cached";
import PixelPatchComponent from "./PixelPatchComponent";
import { getPixelPatchLabelerPropsFromImage } from "../utils/annotation";
import { toggle_refresh_icon_by_id } from "../utils/display";

class ExternalHomepage extends PixelPatchComponent {
    state = {
        numberOfImagesReadyToAnnotate: 0,
    }
    outsourcing_details_refresh_icon_id = "outsourcing-details-refresh-icon";
    job_type = JOB_TYPES.POLYGON_LABELING;
    constructor(props) {
        super(props);
        
        // Bind functions
        this.updateOutsourcingDetails = this.updateOutsourcingDetails.bind(this);
    }

    async componentDidMount() {
        this.updateOutsourcingDetails();
    }

    updateOutsourcingDetails = async () => {
        toggle_refresh_icon_by_id(this.outsourcing_details_refresh_icon_id, true);
        this.setState({
            numberOfImagesReadyToAnnotate: await getNumberOfImagesReadyToAnnotate(this.job_type),
        });
        toggle_refresh_icon_by_id(this.outsourcing_details_refresh_icon_id, false);
    }

    handleStartAnnotating = async () => {
        // Will return just `true` if there are no images to annotate
        const image = await getNextImageToAnnotate(this.job_type);
        if (image !== true) {
            this.navigateTo(
                COMPONENTS.PIXEL_PATCH_LABELER,
                await getPixelPatchLabelerPropsFromImage(
                    image, 
                    false,
                    false,
                    this.job_type,
                    null,
                    null,
                    null,
                    false,
                )
            )
            return;
        }
        this.showToast("No images to annotate.", 3000);
    }

    render() {
        return (
            <div className="ExternalHomepage">
                <div className="outsourcing-container">
                    <div className="outsourcing-info">
                        <header>
                            <h2> Outsourcing Details </h2>
                       </header>
                       {/* Refresh button */}
                       <div className="refresh-container">
                            <button 
                                className="refresh-button"
                                onClick={this.updateOutsourcingDetails}
                            >
                                <CachedIcon id={this.outsourcing_details_refresh_icon_id}/>
                            </button>
                        </div>
                        <p>There are currently <strong>{this.state.numberOfImagesReadyToAnnotate}</strong> images ready to annotate.</p>
                        <p>Click the button below to start annotating!</p>
                        <div className="start-annotating-container">
                            <button 
                                className="start-annotating-button cursor-pointer"
                                onClick={this.handleStartAnnotating}
                            >
                                    Start Annotating
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ExternalHomepage;
// Util functions for image urls.

/**
 * Get the image URL from a s3 key.
 * 
 * @param {string} url 
 * @returns {Promise<HTMLImageElement>} image
 */
export function getMeta(url) {
    return new Promise((res, rej) => {
        let img = new Image();
        img.onload = () => res(img);
        img.onerror = () => rej();
        img.src = url;
    });
}

/**
 * Get the image height and width from a url
 * 
 * @param {string} url
 * @returns {Promise<{height: number, width: number}>} dimensions
 */
export async function getImageDimensions(url) {
    let img = await getMeta(url);
    return {height: img.height, width: img.width};
}
// Util functions for displaying data

/**
 * Replaces underscores with spaces and capitalizes the first letter of each word.
 * 
 * @param {*} str A string.
 * @returns {string} A string with underscores replaced with spaces and the first letter of each word capitalized.
 */
export function makeDisplayString(str) {
    // Cast to string in case the input is not a string
    str = str.toString();
    return str.replace(/_/g, " ").replace(/\b\w/g, l => l.toUpperCase());
}

/**
 * Replaces spaces with underscores and lowercases the first letter of each word.
 * 
 * @param {*} str A string.
 * @returns {string} A string with spaces replaced with underscores and the first letter of each word lowercased.
 */
export function unmakeDisplayString(str) {
    // Cast to string in case the input is not a string
    str = str.toString();
    return str.replace(/ /g, "_").replace(/\b\w/g, l => l.toLowerCase());
}

/**
 * Convert an email address to a display name.
 * 
 * @param {string} email An email address.
 * @returns {string} A display name.
 */
export function emailToDisplayName(email) {
    if (email === undefined || email === null) return "";
    return email.split("@")[0];
}

/** 
 * Display a number in seconds as a string in the format "HH:MM:SS".
 * 
 * @param {number} seconds A number of seconds.
 * @returns {string} A string in the format "HH:MM:SS".
 */
export function secondsToHMS(seconds) {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let secs = seconds % 60;
    // Format the time string
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
}


/**
 * Capitalize the first letter of a string.
 * 
 * @param {string} str A string.
 * @returns {string} A string with the first letter capitalized.
 */
export function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}


/**
 * Updates all of the overlays to be the same size as their image.
 * Assumes that the component has an image_names property, and that
 * each image has an overlay with the id of image_name + "-overlay".
 * 
 * @param {Component} component component that called this function: pass in `this` 
 * @returns {boolean} true if successful, false if not
 */
export function update_all_overlays(component) {
    try {
        for (let image_name of component.state.image_names) {
            update_overlay_by_id(image_name + "-class-overlay");
        }
        return true;
    } catch {
        return false;
    }
}

/**
* Updates one overlay to be the same size as its image.
* 
* @param {string} overlay_id id of the overlay you want to update
*/
export function update_overlay_by_id(overlay_id) {
    // Get the overlay by its id
    let overlay = document.getElementById(overlay_id)

    // Grab the element's parent
    let overlay_parent = overlay.parentElement;

    // Use the element's parent to get its siblings
    let overlay_siblings = overlay_parent.children;

    let image;

    // Loop through all of the sibling elements until you find the sibling that is currently displayed.
    for (let sibling of overlay_siblings) {

        // If the sibling is an overlay or hidden, then its not the sibling we want
        if (sibling.className.includes("hidden") || sibling.className.includes("overlay")) {
            continue
        } 

        // The current sibling is the image we're looking for
        image = sibling;
    }

    // If the image height is currently zero, wait two seconds and then try one more time
    if (image.clientHeight === 0) {
        setTimeout(() => {
            // Set the overlay's width and height to the image's displayed width and height
            overlay.width  = image.clientWidth;
            overlay.height = image.clientHeight;
        }, 500);
    } else {
        // Set the overlay's width and height to the image's displayed width and height
        overlay.width  = image.clientWidth;
        overlay.height = image.clientHeight;
    }
}

/**
 * Toggle the spin of a refresh button from its id.
 * 
 * @param {string} refresh_icon_id id of the refresh icon you want to toggle
 * @param {boolean} is_spinning whether the refresh icon should be spinning
 */
export function toggle_refresh_icon_by_id(refresh_icon_id, is_spinning) {
    // Get the refresh icon by its id
    let refresh_icon = document.getElementById(refresh_icon_id);

    // If the refresh icon should be spinning, add the spin class. Otherwise, remove it.
    if (is_spinning) {
        refresh_icon.classList.add("refresh-spin");
    } else {
        refresh_icon.classList.remove("refresh-spin");
    }
}

/**
 * Check if the horizontal scrollbar is visible on the page.
 */
export function isHorizontalScrollbarVisible() {
    return document.documentElement.scrollWidth > document.documentElement.clientWidth;
}
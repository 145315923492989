// Utils for constructing deterministic URLs

/**
 * Construct a step functions url from an aws arn
 * 
 * @param {string} arn - AWS ARN
 */
export const stepFunctionsUrl = (arn) => {
    // arn:aws:states:us-east-1:{accountId}:execution:aerial_weed_scout:{executionId}
    // https://us-east-1.console.aws.amazon.com/states/home?region=us-east-1#/v2/executions/details/arn:aws:states:us-east-1:{accountId}:execution:aerial_weed_scout:{executionId}
    const parts = arn.split(":");
    const region = parts[3];
    return `https://${region}.console.aws.amazon.com/states/home?region=${region}#/v2/executions/details/${arn}`;
}

/**
 * Construct and active admin url from a sentera id
 * 
 * @param {string} id - Sentera ID
 */
export const activeAdminUrl = (id) => {
    // https://admin{env}.sentera.com/admin/search?sentera_id=<id>
    let env = process.env.REACT_APP_API_ENV === "prod" ? "" : process.env.REACT_APP_API_ENV;
    return `https://admin${env}.sentera.com/admin/search?sentera_id=${id}`;
}
// Constants used throughout the application
// API constants are sourced from here:
// https://github.com/SenteraLLC/py-pixel-patch-api/blob/main/pixel_patch/db/models.py

// ================= Navigation =================
export const NAVIGATION_DESTINATION_KEY = "navigation_destination";
export const NAVIGATION_PROPS_KEY = "navigation_props";

// ================= Toast =================
// Toast Position Keys
export const TOAST_POSITION_KEYS = {
    VERTICAL_TOP: "top",
    VERTICAL_BOTTOM: "bottom",
    HORIZONTAL_LEFT: "left",
    HORIZONTAL_CENTER: "center",
    HORIZONTAL_RIGHT: "right",
}

// Default Toast Duration in milliseconds
export const TOAST_DEFAULT_DURATION = 2500;

// ================= API =================
export const ANALYTICS_ENDPOINT = "analytics";
export const JOBS_ENDPOINT = "jobs";
export const IMAGES_ENDPOINT = "images";
export const AUDIT_LOGS_ENDPOINT = "audit_logs";

// ================= Analytics =================
// Analytic Keys
export const ANALYTIC_KEYS = {
    ID: "id",
    SENTERA_ID: "sentera_id",
    ORGANIZATION_NAME: "organization_name",
    FIELD_NAME: "field_name",
    LAST_EXECUTED_STEP: "last_executed_step",
    STEP_FUNCTION_EXECUTION_ID: "step_function_execution_id",
    ANALYTIC_PRIORITY: "analytic_priority",
    ANALYTIC_STATUS: "analytic_status",
    CREATED_AT: "created_at",
    UPDATED_AT: "updated_at",
    ALLOW_EXTERNAL_ANNOTATION: "allow_external_annotation",
    DO_FINE_TUNING: "do_fine_tuning",
}

// List of Analytic Keys to use when displaying the data
export const ANALYTIC_DISPLAY_KEYS = [
    ANALYTIC_KEYS.ID,
    ANALYTIC_KEYS.SENTERA_ID,
    ANALYTIC_KEYS.ORGANIZATION_NAME,
    ANALYTIC_KEYS.FIELD_NAME,
    ANALYTIC_KEYS.LAST_EXECUTED_STEP,
    // ANALYTIC_KEYS.STEP_FUNCTION_EXECUTION_ID,
    ANALYTIC_KEYS.ANALYTIC_PRIORITY,
    ANALYTIC_KEYS.ANALYTIC_STATUS,
    // ANALYTIC_KEYS.CREATED_AT,
    // ANALYTIC_KEYS.UPDATED_AT,
    ANALYTIC_KEYS.ALLOW_EXTERNAL_ANNOTATION,
    // ANALYTIC_KEYS.DO_FINE_TUNING,
]

// Analytic Statuses
export const ANALYTIC_STATUSES = {
    STEP_FUNCTIONS: "step_functions",
    WEED_PRESSURE_DECISION: "weed_pressure_decision",
    POINT_LABELING: "point_labeling",
    PRE_POLYGON_REVIEW: "pre_polygon_review",
    POLYGON_LABELING: "polygon_labeling",
    FULFILLED: "fulfilled",
    ABANDONED: "abandoned",
}

// List of Analytic Statuses to display by default
export const ANALYTIC_DISPLAY_STATUSES = [
    ANALYTIC_STATUSES.STEP_FUNCTIONS,
    ANALYTIC_STATUSES.WEED_PRESSURE_DECISION,
    ANALYTIC_STATUSES.POINT_LABELING,
    ANALYTIC_STATUSES.PRE_POLYGON_REVIEW,
    ANALYTIC_STATUSES.POLYGON_LABELING,
    // ANALYTIC_STATUSES.FULFILLED,
    // ANALYTIC_STATUSES.ABANDONED,
]

// Status colors
export const ANALYTIC_STATUS_COLOR_MAP = {
    [ANALYTIC_STATUSES.STEP_FUNCTIONS]: "white",
    [ANALYTIC_STATUSES.WEED_PRESSURE_DECISION]: "khaki",
    [ANALYTIC_STATUSES.POINT_LABELING]: "lightblue",
    [ANALYTIC_STATUSES.PRE_POLYGON_REVIEW]: "khaki",
    [ANALYTIC_STATUSES.POLYGON_LABELING]: "lightblue",
    [ANALYTIC_STATUSES.FULFILLED]: "lightgreen",
    [ANALYTIC_STATUSES.ABANDONED]: "pink",
}


// ================= Jobs =================
// Job Keys
export const JOB_KEYS = {
    ID: "id",
    JOB_TYPE: "job_type",
    COMPLETED: "completed",
    CREATED_AT: "created_at",
    UPDATED_AT: "updated",
    ANALYTIC_ID: "analytic_id",
}

// List of Job Keys to use when displaying the data
export const JOB_DISPLAY_KEYS = [
    // JOB_KEYS.ID,
    JOB_KEYS.JOB_TYPE,
    JOB_KEYS.COMPLETED,
    // JOB_KEYS.CREATED_AT,
    // JOB_KEYS.UPDATED_AT,
    // JOB_KEYS.ANALYTIC_ID,
]

// Job Types
export const JOB_TYPES = {
    POINT_LABELING: "point_labeling",
    POLYGON_LABELING: "polygon_labeling",
    NO_JOB: "no_job",
}


// ================= Images =================
// Image Keys
export const IMAGE_KEYS = {
    ID: "id",
    FILENAME: "filename",
    PATH: "path",
    IMAGE_URL: "image_url",
    ANNOTATION_PATH: "annotation_path",
    ORIGINAL_ANNOTATION_PATH: "original_annotation_path",
    ANNOTATION_URL: "annotation_url",
    ANNOTATION_OVERLAY_PATH: "annotation_overlay_path",
    ANNOTATION_OVERLAY_URL: "annotation_overlay_url",
    MASK_PATH: "mask_path",
    MASK_URL: "mask_url",
    IMAGE_STATUS: "image_status",
    LOCKED_BY: "locked_by",
    CREATED_AT: "created_at",
    UPDATED_AT: "updated_at",
    JOB_ID: "job_id",
    WEED_ANNOTATION_COUNT: "weed_annotation_count",
    CROP_ANNOTATION_COUNT: "crop_annotation_count",
}

// List of Image Keys to use when displaying the data
export const IMAGE_DISPLAY_KEYS = [
    // IMAGE_KEYS.ID,
    IMAGE_KEYS.FILENAME,
    // IMAGE_KEYS.PATH,
    // IMAGE_KEYS.IMAGE_URL,
    // IMAGE_KEYS.ANNOTATION_PATH,
    // IMAGE_KEYS.ORIGINAL_ANNOTATION_PATH,
    // IMAGE_KEYS.ANNOTATION_URL,
    // IMAGE_KEYS.ANNOTATION_OVERLAY_PATH,
    // IMAGE_KEYS.ANNOTATION_OVERLAY_URL,
    // IMAGE_KEYS.MASK_PATH,
    // IMAGE_KEYS.MASK_URL,
    IMAGE_KEYS.IMAGE_STATUS,
    IMAGE_KEYS.LOCKED_BY,
    // IMAGE_KEYS.CREATED_AT,
    // IMAGE_KEYS.UPDATED_AT,
    // IMAGE_KEYS.JOB_ID,
    // IMAGE_KEYS.WEED_ANNOTATION_COUNT,
    // IMAGE_KEYS.CROP_ANNOTATION_COUNT,
]

// Image Statuses
export const IMAGE_STATUSES = {
    NOT_USED: "not_used",
    READY_TO_ANNOTATE: "ready_to_annotate",
    ANNOTATING: "annotating",
    READY_TO_REVIEW: "ready_to_review",
    REVIEWING: "reviewing",
    COMPLETE: "complete",
    EXCLUDED: "excluded",
}

// Image Statuses to Display on the Jobs Page
export const IMAGE_DISPLAY_STATUSES = [
    // IMAGE_STATUSES.NOT_USED,
    IMAGE_STATUSES.READY_TO_ANNOTATE,
    IMAGE_STATUSES.ANNOTATING,
    IMAGE_STATUSES.READY_TO_REVIEW,
    IMAGE_STATUSES.REVIEWING,
    IMAGE_STATUSES.COMPLETE,
    // IMAGE_STATUSES.EXCLUDED,
]

// Status colors
export const IMAGE_STATUS_COLOR_MAP = {
    [IMAGE_STATUSES.READY_TO_ANNOTATE]: "lightblue",
    [IMAGE_STATUSES.ANNOTATING]: "khaki",
    [IMAGE_STATUSES.READY_TO_REVIEW]: "lightblue",
    [IMAGE_STATUSES.REVIEWING]: "khaki",
    [IMAGE_STATUSES.COMPLETE]: "lightgreen",
    [IMAGE_STATUSES.EXCLUDED]: "pink",
}

export const REVIEW_PENDING = "review_pending";

// Only show the image status if it is not in a review status
export const IMAGE_ANNOTATION_STATUS_FROM_IMAGE_STATUS = {
    [IMAGE_STATUSES.READY_TO_ANNOTATE]: IMAGE_STATUSES.READY_TO_ANNOTATE,
    [IMAGE_STATUSES.ANNOTATING]: IMAGE_STATUSES.ANNOTATING,
    // In any review state, show the annotation as complete
    [IMAGE_STATUSES.READY_TO_REVIEW]: IMAGE_STATUSES.COMPLETE,
    [IMAGE_STATUSES.REVIEWING]: IMAGE_STATUSES.COMPLETE,
    [IMAGE_STATUSES.COMPLETE]: IMAGE_STATUSES.COMPLETE,
    [IMAGE_STATUSES.EXCLUDED]: IMAGE_STATUSES.EXCLUDED,
}

// List of all valid image statuses, exlude the review statuses
export const IMAGE_ANNOTATION_STATUSES_LIST = Object.values(IMAGE_STATUSES).filter(status => ![IMAGE_STATUSES.READY_TO_REVIEW, IMAGE_STATUSES.REVIEWING, IMAGE_STATUSES.NOT_USED].includes(status));

// Only show the image status if it *is* in a review status
export const IMAGE_REVIEW_STATUS_FROM_IMAGE_STATUS = {
    [IMAGE_STATUSES.READY_TO_ANNOTATE]: REVIEW_PENDING,
    [IMAGE_STATUSES.ANNOTATING]: REVIEW_PENDING,
    [IMAGE_STATUSES.READY_TO_REVIEW]: IMAGE_STATUSES.READY_TO_REVIEW,
    [IMAGE_STATUSES.REVIEWING]: IMAGE_STATUSES.REVIEWING,
    [IMAGE_STATUSES.COMPLETE]: IMAGE_STATUSES.COMPLETE,
    [IMAGE_STATUSES.EXCLUDED]: IMAGE_STATUSES.EXCLUDED,
}

// List of all valid image statuses, include the review statuses
export const IMAGE_REVIEW_STATUSES_LIST = Object.values(IMAGE_STATUSES).filter(status => ![IMAGE_STATUSES.READY_TO_ANNOTATE, IMAGE_STATUSES.ANNOTATING, IMAGE_STATUSES.NOT_USED].includes(status));


// ================= Audit Logs =================
// Audit Log Keys
export const AUDIT_LOG_KEYS = {
    ID: "id",
    EVENT_NAME: "event_name",
    DETAILS: "details",
    CREATED_BY_EMAIL: "created_by_email",
    CREATED_AT: "created_at",
    UPDATED_AT: "updated_at",
    OBJECT_ID: "object_id",
    OBJECT_TYPE: "object_type",
}

// Keys for the details object in the audit log
export const AUDIT_LOG_DETAILS_KEYS = {
    ANALYTIC_STATUS_OLD: "analytic_status_old",
    ANALYTIC_STATUS_NEW: "analytic_status_new",
    IMAGE_STATUS_OLD: "image_status_old",
    IMAGE_STATUS_NEW: "image_status_new",
}

// List of Audit Log Keys to use when displaying the data
export const AUDIT_LOG_DISPLAY_KEYS = [
    // AUDIT_LOG_KEYS.ID,
    AUDIT_LOG_KEYS.EVENT_NAME,
    // AUDIT_LOG_KEYS.DETAILS,
    AUDIT_LOG_KEYS.CREATED_BY_EMAIL,
    AUDIT_LOG_KEYS.CREATED_AT,
    // AUDIT_LOG_KEYS.UPDATED_AT,
    // AUDIT_LOG_KEYS.OBJECT_ID,
    // AUDIT_LOG_KEYS.OBJECT_TYPE,
]

export const AUDIT_LOG_EVENT_NAMES = {
    ANALYTIC_CREATED: "analytic_created",
    JOB_CREATED: "job_created",
    IMAGE_CREATED: "image_created",
    LABELING_JOB_CREATED : "labeling_job_created",
    ANALYTIC_STATUS_CHANGED: "analytic_status_changed",
    IMAGE_STATUS_CHANGED: "image_status_changed",
    JOB_COMPLETED: "job_completed",
    IMAGE_ANNOTATIONS_SAVED: "image_annotations_saved",
    UPDATE_ATTRIBUTE: "update_attribute",
}

// Derived from STATUS_CHANGED events
export const AUDIT_LOG_DISPLAY_NAMES = {
    WEED_PRESSURE_LOW: "Weed Pressure Low",
    WEED_PRESSURE_HIGH: "Weed Pressure High",
    PRE_POLYGON_REVIEW_COMPLETED: "Pre-Polygon Review Complete",
    POINT_LABELING_COMPLETED: "Point Labeling Completed",
    POLYGON_LABELING_COMPLETED: "Polygon Labeling Completed",
    IMAGE_LOCKED: "Image Locked",
    IMAGE_UNLOCKED: "Image Unlocked",
    IMAGE_EXCLUDED: "Image Excluded",
    IMAGE_INCLUDED: "Image Included",
    IMAGE_ANNOTATING_COMPLETED: "Image Annotating Completed",
    IMAGE_REVIEW_COMPLETED: "Image Review Completed",
    IMAGE_REVIEW_FAILED: "Image Review Failed",
}

// ================= Components =================
// List of valid top-level components
// The order of this list determines the order of the links in the navbar
export const COMPONENTS = {
    LOGIN: "Login",
    LOGOUT: "Logout",
    HOME: "Home",
    EXTERNAL_HOMEPAGE: "Outsourced Jobs",
    ANALYTICS: "Analytics",
    GRID: "Grid",
    JOBS: "Jobs",
    ANALYTIC_DETAILS: "Analytic Details",
    PIXEL_PATCH_LABELER: "Pixel Patch Labeler",
}

// List of all valid top-level components
export const COMPONENTS_LIST = Object.values(COMPONENTS);

export const COMMON_COMPONENTS_LIST = [
    COMPONENTS.LOGIN,
    COMPONENTS.LOGOUT,
    COMPONENTS.HOME,
    COMPONENTS.PIXEL_PATCH_LABELER,
]

// List of components that are only for internal use, in addition to the common components
export const INTERNAL_COMPONENTS_LIST = [
    COMPONENTS.ANALYTICS,
    COMPONENTS.GRID,
    COMPONENTS.JOBS,
    COMPONENTS.ANALYTIC_DETAILS,
]

// List of components that are exposed to external users only, in addition to the common components
export const EXTERNAL_COMPONENTS_LIST = [
    COMPONENTS.EXTERNAL_HOMEPAGE,
]

// List of components that should not be displayed in the navbar
export const HIDDEN_COMPONENTS_LIST = [
    COMPONENTS.LOGIN,
    COMPONENTS.LOGOUT,
    COMPONENTS.GRID,
    COMPONENTS.JOBS,
    COMPONENTS.ANALYTIC_DETAILS,
    COMPONENTS.PIXEL_PATCH_LABELER,
]

// ================= Login =================
// Login Page Modes
export const LOGIN_MODES = {
    SIGN_IN: "Sign In",
    SIGN_UP: "Sign Up",
    RESET_PASSWORD: "Reset Password",
}

// Default user
export const DEFAULT_USER = "PixelPatchUser";

// User groups
export const USER_GROUPS = {
    INTERNAL: "Internal",
    EXTERNAL: "External",
}

// ================= Grid =================
export const GRID_KEYBIND_NAMES = {
    TOGGLE_IMAGE_LAYER: "toggle_image_layer_keybind",
    TOGGLE_ALL_IMAGE_LAYERS: "toggle_all_image_layers_keybind",
    NEXT_ROW: "next_row_keybind",
    PREV_ROW: "prev_row_keybind",
    INCREASE_IMAGE_SIZE: "increase_image_size_keybind",
    DECREASE_IMAGE_SIZE: "decrease_image_size_keybind",
    TOGGLE_ANNOTATION_OVERLAY: "toggle_annotation_overlay_keybind",
    TOGGLE_ALL_ANNOTATION_OVERLAYS: "toggle_all_annotation_overlays_keybind",
}

export const GRID_KEYBINDS = {
    [GRID_KEYBIND_NAMES.TOGGLE_IMAGE_LAYER]: "b",
    [GRID_KEYBIND_NAMES.TOGGLE_ALL_IMAGE_LAYERS]: "B",
    [GRID_KEYBIND_NAMES.NEXT_ROW]: "n",
    [GRID_KEYBIND_NAMES.PREV_ROW]: "h",
    [GRID_KEYBIND_NAMES.INCREASE_IMAGE_SIZE]: "=",
    [GRID_KEYBIND_NAMES.DECREASE_IMAGE_SIZE]: "-",
    [GRID_KEYBIND_NAMES.TOGGLE_ANNOTATION_OVERLAY]: "v",
    [GRID_KEYBIND_NAMES.TOGGLE_ALL_ANNOTATION_OVERLAYS]: "V",
}

export const GRID_MODES = {
    MANAGE_IMAGES: "manage_images",
    WEED_PRESSURE_DECISION: ANALYTIC_STATUSES.WEED_PRESSURE_DECISION,
    PRE_POLYGON_REVIEW: ANALYTIC_STATUSES.PRE_POLYGON_REVIEW,
}

export const DEFAULT_N_TILES = 40;

// ================= Analytic Details =================
// Column names for the job details table
export const JOB_DETAILS_COLUMN_NAMES = {
    JOB: "job",
    COMPLETE: "complete",
    LAUNCH: "launch",
}

// ================= Pixel Patch Labeler =================
// ULabel toolbox items
export const ULABEL_TOOLBOX_ITEMS = {
    ModeSelect: 0,
    ZoomPan: 1,
    AnnotationResize: 2,
    AnnotationID: 3,
    RecolorActive: 4,
    ClassCounter: 5,
    KeypointSlider: 6,
    SubmitButtons: 7,
    FilterDistance: 8,
    Brush: 9,
}

// ULabel config data
export const ULABEL_CONFIG_DATA = {
    "default_toolbox_item_order": [
        ULABEL_TOOLBOX_ITEMS.ModeSelect,
        ULABEL_TOOLBOX_ITEMS.Brush,
        ULABEL_TOOLBOX_ITEMS.AnnotationResize,
        ULABEL_TOOLBOX_ITEMS.AnnotationID,
        ULABEL_TOOLBOX_ITEMS.ClassCounter,
        ULABEL_TOOLBOX_ITEMS.SubmitButtons,
    ],
    "toggle_brush_mode_keybind": "f",
    "create_bbox_on_initial_crop": "|"
}

export const INITIAL_LINE_SIZE = 4;

export const SUBIMAGE_CLASS_ID = 11;

export const SUBIMAGE_SUBTASK_KEY = "subimage_bounds";

// ULabel subtask definition for point labeling
export const POINT_QA_SUBTASKS = {
    "weeds_subtask": {
        "display_name": "Weeds Point QA",
        "classes": [
            {
                "name": "Weed",
                "color": "#ff04d4",
                "id": 1,
                "keybind": "1",
            }
        ],
        "allowed_modes": [
            "point", "delete_polygon", "delete_bbox"
        ],
        "resume_from": null
    },
    [SUBIMAGE_SUBTASK_KEY]: {
        "display_name": "Subimage Bounds",
        "classes": [
            {
                "name": "Subimage Bounds",
                "color": "black",
                "id": SUBIMAGE_CLASS_ID,
            }
        ],
        "allowed_modes": ["bbox"],
        "resume_from": null,
        "read_only": true,
        "inactive_opacity": 0.33,
    }
}

// ULabel subtask definition for polygon labeling
export const POLYGON_QA_SUBTASKS = {
    "weeds_subtask": {
        "display_name": "Weeds Polygon QA",
        "classes": [
            {
                "name": "Weed",
                "color": "#ff04d4",
                "id": 1,
                "keybind": "1",
            },
            {
                "name": "Crop",
                "color": "#04ecc4",
                "id": 0,
                "keybind": "2",
            }
        ],
        "allowed_modes": [
            "polygon", "delete_polygon", "delete_bbox"
        ],
        "resume_from": null
    }
}